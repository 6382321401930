import React, { useState } from 'react';
import DoubleRightCheck from 'assets/img/chevron-right-double.svg';
import { useHistory } from 'react-router-dom';
import Carousel from 'components/new_components/carousel/Carousel';
import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import { useSelector } from 'react-redux';

const PeopleComputers = (props) => {
    const { switch_on, handleOpen } = props;
    const { people_summary } = useSelector((state) => state?.generalReducers);
    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const [tab, setTab] = useState('agent');
    const router = useHistory();
    const gotoPage = (link) => {
        router.push(link);
    };

    const dashboardResult = useSelector((state) => state?.dashboardReducers?.dashboardResult);
    const employee_with_agents = dashboardResult?.employees_with_installed_agents_count || 0;
    const employee_with_background_check = people_summary?.filter((people) => people?.background_check)?.length;
    const responded_percentage = (employee_with_agents * 100) / people_summary?.length;
    const background_responded_percentage = (employee_with_background_check * 100) / people_summary?.length;
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    const keywords = merchant_info?.intel_keywords?.['People & Computers'];

    const intel =
        switch_on && all_smart_intel?.['People & Computers']
            ? Object?.values(all_smart_intel?.['People & Computers'])
                  ?.map((option) => option)
                  ?.flat()
                  ?.filter((el) => {
                      return keywords?.some(
                          (keyword) =>
                              el?.title?.toLowerCase()?.includes(keyword) ||
                              el?.market_place?.toLowerCase()?.includes(keyword)
                      );
                  })
            : [];

    function RenderTab() {
        switch (tab) {
            case 'agent':
                return (
                    <div className="flex items-center gap-10">
                        <div className="relative w-full xl:mt-5 xl:flex justify-center items-center md:block">
                            <div className="absolute top-5 2xl:left-[15%] md:left-[30%]">
                                <div className="absolute xl:w-[190px] xl:h-[190px] md:w-[180px] md:h-[180px] rounded-full bg-[#B1C5FF]">
                                    <div
                                        className={`absolute rounded-full w-[70px] h-[70px] xl:border-[110px] md:border-[90px] border-transparent rotate-45 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform
                        
                         ${
                             responded_percentage?.length > 70
                                 ? 'border-y-[#395BA9] border-x-[#395BA9]'
                                 : responded_percentage?.length > 69
                                 ? 'border-y-[#395BA9] border-r-[#395BA9]'
                                 : responded_percentage?.length > 50
                                 ? 'border-y-[#395BA9] border-r-[#395BA9]'
                                 : responded_percentage?.length > 33
                                 ? 'border-t-[#395BA9] border-r-[#395BA9]'
                                 : responded_percentage?.length > 25
                                 ? 'border-t-[#395BA9] border-r-[#395BA9]'
                                 : responded_percentage?.length === 0
                                 ? 'border-y-[#B1C5FF] border-x-[#B1C5FF]'
                                 : 'border-t-[#395BA9]'
                         }`}
                                    >
                                        <div className="bg-white rounded-full w-[120px] h-[120px] flex justify-center items-center -translate-y-1/2 -translate-x-1/2 transform -rotate-45">
                                            <p className="text-[#202D66] font-bold text-[34px]">
                                                {Math.round(people_summary?.length)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="2xl:mt-[5rem] md:mt-[15rem] 2xl:ml-[15rem] md:ml-0 2xl:block md:flex justify-center items-center flex-col">
                                <div className="flex items-center gap-3">
                                    <div className="w-[20px] h-[20px] bg-[#395BA9] rounded-full"></div>
                                    <p className="text-[#475569] text-sm font-semibold">With Agent</p>
                                    <div className="w-[20px] h-[20px] bg-[#94A3B8] rounded p-0.5 flex justify-center items-center">
                                        <p className="text-white text-xs font-medium">
                                            {Math.round(employee_with_agents)}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-3 mt-[1rem]">
                                    <div className="w-[20px] h-[20px] bg-[#B1C5FF] rounded-full"></div>
                                    <p className="text-[#475569] text-sm font-semibold">Without Agent</p>
                                    <div className="w-[20px] h-[20px] bg-[#94A3B8] rounded p-0.5 flex justify-center items-center">
                                        <p className="text-white text-xs font-medium">
                                            {Math.round(dashboardResult?.employees_without_installed_agents_count || 0)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'background_check':
                return (
                    <div className="flex items-center gap-10">
                        <div className="relative w-full xl:mt-5 xl:flex justify-center items-center md:block">
                            <div className="absolute top-5 2xl:left-[15%] md:left-[30%]">
                                <div className="absolute xl:w-[190px] xl:h-[190px] md:w-[180px] md:h-[180px] rounded-full bg-[#B1C5FF]">
                                    <div
                                        className={`absolute rounded-full w-[70px] h-[70px] xl:border-[110px] md:border-[100px] border-transparent rotate-45 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform
                    
                     ${
                         background_responded_percentage?.length > 70
                             ? 'border-y-[#395BA9] border-x-[#395BA9]'
                             : background_responded_percentage?.length > 69
                             ? 'border-y-[#395BA9] border-r-[#395BA9]'
                             : background_responded_percentage?.length > 50
                             ? 'border-y-[#395BA9] border-r-[#395BA9]'
                             : background_responded_percentage?.length > 33
                             ? 'border-t-[#395BA9] border-r-[#395BA9]'
                             : background_responded_percentage?.length > 25
                             ? 'border-t-[#395BA9] border-r-[#395BA9]'
                             : background_responded_percentage?.length === 0
                             ? 'border-y-[#B1C5FF] border-x-[#B1C5FF]'
                             : 'border-t-[#395BA9]'
                     }`}
                                    >
                                        <div className="bg-white rounded-full w-[120px] h-[120px] flex justify-center items-center -translate-y-1/2 -translate-x-1/2 transform -rotate-45">
                                            <p className="text-[#202D66] font-bold text-[34px]">
                                                {Math.round(people_summary?.length)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="2xl:mt-[5rem] md:mt-[15rem] 2xl:ml-[15rem] md:ml-0 2xl:block md:flex justify-center items-center flex-col">
                                <div className="flex items-center gap-3">
                                    <div className="w-[20px] h-[20px] bg-[#395BA9] rounded-full"></div>
                                    <p className="text-[#475569] text-sm font-semibold">Verified</p>
                                    <div className="w-[20px] h-[20px] bg-[#94A3B8] rounded p-0.5 flex justify-center items-center">
                                        <p className="text-white text-xs font-medium">
                                            {Math.round(employee_with_background_check)}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-3 mt-[1rem]">
                                    <div className="w-[20px] h-[20px] bg-[#B1C5FF] rounded-full"></div>
                                    <p className="text-[#475569] text-sm font-semibold">Not Verified</p>
                                    <div className="w-[20px] h-[20px] bg-[#94A3B8] rounded p-0.5 flex justify-center items-center">
                                        <p className="text-white text-xs font-medium">
                                            {Math.round(people_summary?.length)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="relative w-full mt-5 xl:flex justify-center items-center md:block">
                        <div className="absolute top-5 xl:left-[15%] md:left-[40%]">
                            <div className="absolute w-[190px] h-[190px] rounded-full bg-[#B1C5FF]">
                                <div
                                    className={`absolute rounded-full w-[70px] h-[70px] border-[110px] border-transparent rotate-45 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform
                    
                     ${
                         responded_percentage?.length > 70
                             ? 'border-y-[#395BA9] border-x-[#395BA9]'
                             : responded_percentage?.length > 69
                             ? 'border-y-[#395BA9] border-r-[#395BA9]'
                             : responded_percentage?.length > 50
                             ? 'border-y-[#395BA9] border-r-[#395BA9]'
                             : responded_percentage?.length > 33
                             ? 'border-t-[#395BA9] border-r-[#395BA9]'
                             : responded_percentage?.length > 25
                             ? 'border-t-[#395BA9] border-r-[#395BA9]'
                             : responded_percentage?.length === 0
                             ? 'border-y-[#B1C5FF] border-x-[#B1C5FF]'
                             : 'border-t-[#395BA9]'
                     }`}
                                >
                                    <div className="bg-white rounded-full w-[120px] h-[120px] flex justify-center items-center -translate-y-1/2 -translate-x-1/2 transform -rotate-45">
                                        <p className="text-[#202D66] font-bold text-[34px]">
                                            {Math.round(people_summary?.length)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[5rem] ml-[15rem]">
                            <div className="flex items-center gap-3">
                                <div className="w-[20px] h-[20px] bg-[#395BA9] rounded-full"></div>
                                <p className="text-[#475569] text-sm font-semibold">With Agent</p>
                                <div className="w-[20px] h-[20px] bg-[#94A3B8] rounded p-0.5 flex justify-center items-center">
                                    <p className="text-white text-xs font-medium">{Math.round(employee_with_agents)}</p>
                                </div>
                            </div>
                            <div className="flex items-center gap-3 mt-[1rem]">
                                <div className="w-[20px] h-[20px] bg-[#B1C5FF] rounded-full"></div>
                                <p className="text-[#475569] text-sm font-semibold">Without Agent</p>
                                <div className="w-[20px] h-[20px] bg-[#94A3B8] rounded p-0.5 flex justify-center items-center">
                                    <p className="text-white text-xs font-medium">
                                        {Math.round(dashboardResult?.employees_without_installed_agents_count || 0)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }
    }
    return (
        <div className="bg-white border-1 border-[#F1F5F9] rounded-md p-3">
            <div className="flex justify-between items-center">
                <h4 className="text-[#2B3674] text-lg font-semibold">People & Computers</h4>
                <button
                    className="bg-[#F1F5F9] py-1 px-2 rounded-md"
                    onClick={() => gotoPage('/merchant/onboarding/computers')}
                >
                    <img src={DoubleRightCheck} alt="DoubleRightCheck" />
                </button>
            </div>
            <div className="mt-[1.5rem] bg-[#F1F5F9] rounded p-2.5 flex justify-between items-center gap-2 w-full">
                <div
                    className={`${
                        tab === 'agent' ? 'bg-white shadow-md transition ease-in-out duration-500' : ''
                    } p-2 flex justify-center items-center w-[50%] rounded cursor-pointer`}
                    onClick={() => setTab('agent')}
                >
                    <p className={`${tab === 'agent' ? 'text-[#395BA9]' : 'text-[#64748B]'} text-sm font-bold`}>
                        With agent
                    </p>
                </div>
                <div
                    className={`${
                        tab === 'background_check' ? 'bg-white shadow-md transition ease-in-out duration-500' : ''
                    } p-2 flex justify-center items-center w-[50%] rounded cursor-pointer`}
                    onClick={() => setTab('background_check')}
                >
                    <p
                        className={`${
                            tab === 'background_check' ? 'text-[#395BA9]' : 'text-[#64748B]'
                        } text-sm font-bold`}
                    >
                        Background check
                    </p>
                </div>
            </div>

            {switch_on && intel?.length ? (
                <div className=" my-[1.5rem] transition ease-in-out duration-500">
                    <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center w-full 2xl:h-[50px] xl:h-[70px] md:[100px]">
                        <div className="flex items-center gap-2 w-[85%]">
                            <img src={SmartIntelLogo} alt="SmartIntelLogo" className="xl:block md:hidden" />
                            <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0.5 w-fit xl:block md:hidden">
                                {intel?.length}
                            </p>
                            <Carousel
                                slides={intel}
                                handleOpen={handleOpen}
                                type="People & Computers"
                                data={all_smart_intel?.['People & Computers']}
                            />
                        </div>
                        <img src={ArrowRight} alt="ArrowRight" />
                    </div>
                </div>
            ) : null}
            <RenderTab />
        </div>
    );
};

export default PeopleComputers;
// border-t-[#395BA9]
// `}
