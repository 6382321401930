import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import Cloud from 'assets/img/cloud.svg';
import Docx from 'assets/img/docxfile.svg';
import Pdf from 'assets/img/pngfile.png';
import AppButton from 'components/new_components/AppButton';
import { useRef, useState } from 'react';
import { accept } from 'validate';
import Stepper from './Stepper';

const StepOne = (props) => {
    const theme = useTheme();
    const { video, pdf, docx } = accept;
    const {
        dragEnter,
        dragLeave,
        dragDrop,
        dragOver,
        uploadImage,
        img,
        new_document,
        toNextStep,
        moveToStep,
        step,
        data,
        type,
        imageLoading,
    } = props;

    const [stepOneError, setStepOneError] = useState(false);

    const inpurtRef = useRef(null);

    const getFileName = (url) => {
        const fileName = url?.split('/')?.[4];
        return fileName;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (new_document) {
            setStepOneError(false);
            toNextStep();
        } else {
            setStepOneError(true);
        }
    };
    return (
        <>
            <Typography
                sx={{
                    fontSize: 11,
                    color: theme.palette.gray[40],
                    fontWeight: 400,
                    backgroundColor: '#F8F8F8',
                    p: 3,
                    borderRadius: '8px',
                    my: 2,
                    border: `1px solid ${theme.palette.neutral[95]}`,
                }}
            >
                We advise you to only overwrite a policy if you have made significant and not minor changes
            </Typography>
            <Typography sx={{ fontSize: 10, fontWeight: 600, mb: 0.5 }}>existing policy</Typography>
            <Stack
                direction="row"
                alignItems="center"
                sx={{ backgroundColor: '#F8F8F8', borderRadius: '8px', py: 0.5, px: 1 }}
                gap={2}
            >
                <img src={Pdf} className="w-[20px] h-[24px]" alt="pdf" />
                <Typography
                    sx={{ fontSize: 11, fontWeight: 500, color: theme.palette.gray[40], letterSpacing: '0.5px' }}
                >
                    {getFileName(data?.file)}
                </Typography>
            </Stack>
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: '1.5rem',
                        border: '0.7px solid #C7C6CA',
                        borderRadius: '4px',
                        width: '100%',
                        height: '90px',
                        cursor: 'pointer',
                    }}
                    id="drop-zone"
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={dragDrop}
                    onDragOver={dragOver}
                    onClick={() => inpurtRef.current.click()}
                >
                    <label htmlFor={data?.id} style={{ cursor: 'pointer' }}>
                        <img src={Cloud} alt={'Cloud'} />
                    </label>
                    <input
                        ref={inpurtRef}
                        type="file"
                        accept={type === 'document' ? `${pdf},${docx}` : `${video}`}
                        onChange={(e) => uploadImage(e)}
                        hidden
                        id={data?.id}
                    />
                    <Typography sx={{ color: '#303034', fontSize: '12px', fontWeight: 600, marginTop: '0.5rem' }}>
                        <strong>Click to upload</strong>{' '}
                        <span style={{ color: '#77777A', fontWeight: 400 }}>or drag and drop a file</span>
                    </Typography>
                    <Typography sx={{ color: '#ACAAAF', fontSize: '10px', fontWeight: 600 }}>PDF, DOCX MP4</Typography>
                </Box>
                {new_document?.name ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '1rem',
                        }}
                    >
                        <Typography sx={{ fontSize: 10, fontWeight: 600, mb: 0.5 }}>new policy</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={
                                    new_document?.type === 'application/pdf'
                                        ? Pdf
                                        : new_document?.type ===
                                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                        ? Docx
                                        : new_document?.type === 'application/png'
                                        ? img
                                        : new_document?.type === 'application/jpeg'
                                        ? img
                                        : null
                                }
                                alt="img"
                            />
                            <Typography sx={{ marginLeft: '1rem', color: '#5E5E62', fontSize: '11px' }}>
                                {new_document.name}
                            </Typography>
                        </Box>
                    </Box>
                ) : null}
                {stepOneError ? <FormHelperText sx={{ color: '#D91B1B' }}>choose a file</FormHelperText> : null}
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 3 }}>
                    <Stepper currentStep={step} totalSteps={2} onClick={moveToStep} />

                    <AppButton
                        name={imageLoading ? 'Please wait..' : 'Next'}
                        color="primary"
                        variant="contained"
                        disableElevation
                        type="submit"
                        disabled={imageLoading}
                    />
                </Stack>
            </form>
        </>
    );
};

export default StepOne;
