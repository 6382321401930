import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import AppTable from 'components/new_components/app-table';
// import AuditTableAction from './AuditTableAction';
import AppTag from 'components/new_components/AppTags';
// import CompleteIcon from 'assets/img/audit/completedIcon.svg';
// import ReviewIcon from 'assets/img/audit/reviewIcon.svg';
// import AuditIcon from 'assets/img/audit/auditIcon.svg';
//compliance standards
// import PcidssIcon from 'assets/img/audit/pcidss.svg';
// import soc2Icon from 'assets/img/audit/soc2-type1.svg';
// import ndprIcon from 'assets/img/audit/ndpr.svg';
// import iso27001Icon from 'assets/img/audit/iso27001.svg';
// import BlockchainIcon from 'assets/img/audit/blockchain.svg';
// import { BiChevronDown } from 'react-icons/bi';
import { IoMdDownload } from 'react-icons/io';
import AuditDeleteModal from './modals/AuditDeleteModal';
import AuditDownloadModal from './modals/AuditDownloadModal';
// import { useHistory } from 'react-router-dom';
import { IconButton, useTheme } from '@mui/material';
import AppInput from 'components/new_components/AppInput';
import { HorizontalRuleRounded } from '@mui/icons-material';
import { AUDIT_STATUS } from './utils';
import ViewCertificate from './ViewCertificate/ViewCertificate';

// redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { GetAllAuditRequests, CreateAuditRequest } from 'store/actions/auditActions';
// import LoadingState from 'components/new_components/LoadingState';
import AppButton from 'components/new_components/AppButton';

//Userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import { GetRegisteredClients } from 'store/actions/ImplementerAction';
import { GetAllAuditRequestsImplementer } from 'store/actions/auditActions';

const NO_PER_PAGE = 8;

const AuditTableRecord = (props) => {
    // hooks
    const theme = useTheme();
    // const history = useHistory();

    // props
    const {
        GetAllAuditRequests,
        all_audit_requests,
        userguide_info,
        merchant_info,
        all_article_header,
        GetAllAuditRequestsImplementer,
    } = props;

    // states
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [view, setView] = useState('table');
    // const [dropdownAnchor, setDropdownAnchor] = useState(null);
    // const [creatingRequest, setCreatingRequest] = useState({});
    const [data, setData] = useState({});
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [query, setQuery] = useState('');

    // redux
    const dispatch = useDispatch();
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const registered_clients = useSelector((state) => state?.implementerReducer?.registered_clients);
    const client_id = useSelector((state) => state?.implementerReducer?.client_id);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const STEPS_LENGTH = 1;

    const steps = [
        {
            title: <UserGuideHeader title="View Certificate" id={1} length={STEPS_LENGTH} />,
            description: 'Add users to your account for an efficient compliance process.',
            target: () => ref1.current,
            placement: 'leftTop',
        },
        // {
        //     title: 'Download',
        //     description: 'Download your compliance certificate once the audit process is done',
        //     target: () => ref2.current,
        // },
    ];

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Audit'),
        [all_article_header]
    );

    // generate report dropdown
    // const handleDropdownOpen = (event) => {
    //     setDropdownAnchor(event.currentTarget);
    // };
    // const handleDropdownClose = () => {
    //     setDropdownAnchor(null);
    // };

    //Delete Modal
    // const openDeleteModal = (data) => {
    //     setDeleteModalOpen(true);
    //     setData(data);
    // };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setData({});
    };
    const openDownloadModal = (data = {}) => {
        setDownloadModalOpen(true);
        setData(data);
    };
    const closeDownloadModal = () => {
        setDownloadModalOpen(false);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };
    // const gotoPlanSettings = async (standard) => {
    //     const pending_request = all_audit_requests?.find((request) => request?.compliance === standard?.toLowerCase());
    //     if (!pending_request) {
    //         setCreatingRequest({ ...creatingRequest, [standard]: true });
    //         const res = await CreateAuditRequest({ compliance: standard.toLowerCase(), status: AUDIT_STATUS.DRAFT });
    //         setCreatingRequest({ ...creatingRequest, [standard]: false });
    //         if (!res?.success) {
    //             return toast.error("Something went wrong, couldn't create request draft");
    //         }
    //     }
    //     history.push(`/merchant/audits/${standard?.toLowerCase()}`);
    // };
    const getAllAuditRequests = async () => {
        setLoading(true);
        const res =
            user_info?.user_type === 'implementer'
                ? await GetAllAuditRequestsImplementer(client_id)
                : await GetAllAuditRequests();

        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't get audits.");
        }
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ audits: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, audits: false });
        setOpenArticle(false);
    };

    // useEffect
    useEffect(() => {
        getAllAuditRequests();
    }, []);

    useEffect(() => {
        const getRegisteredClients = async () => {
            const res = await dispatch(GetRegisteredClients());
            if (!res?.status) {
                toast.error(res?.message);
            }
        };

        getRegisteredClients();
    }, []);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.audits ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.audits]);

    // const compliance_standards = ['PCIDSS', 'NDPR', 'SOC2', 'ISO27001'];

    const columns = [
        {
            title: 'Compliance Standard',
            render: (row) => (
                <div className="flex items-center">
                    {/* <img
                        src={
                            row.compliance === 'pcidss'
                                ? PcidssIcon
                                : row.compliance === 'soc2'
                                ? soc2Icon
                                : row.compliance === 'ndpr'
                                ? ndprIcon
                                : row.compliance === 'iso27001'
                                ? iso27001Icon
                                : BlockchainIcon
                        }
                        alt="icon"
                        className="w-[55px]"
                    /> */}
                    <span className="pl-3 uppercase">{row.compliance}</span>
                </div>
            ),
        },
        {
            title: 'Status',
            render: (row) => (
                <AppTag
                    text={
                        row.status === AUDIT_STATUS.COMPLETE
                            ? 'Completed'
                            : row.status === AUDIT_STATUS.IN_REVIEW
                            ? 'In Review'
                            : row.status === AUDIT_STATUS.AUDITING
                            ? 'Auditing...'
                            : row.status === AUDIT_STATUS.DISAPPROVED
                            ? 'Disapproved'
                            : 'Draft'
                    }
                    type={
                        row.status === AUDIT_STATUS.COMPLETE
                            ? 'success'
                            : row.status === AUDIT_STATUS.IN_REVIEW
                            ? 'pending'
                            : row.status === AUDIT_STATUS.AUDITING
                            ? 'primary'
                            : row.status === AUDIT_STATUS.DISAPPROVED
                            ? 'failed'
                            : 'default'
                    }
                    // Finished this before going to the next thing
                    // textStyle={['completed', 'review'].includes(row.status) ? 'italics' : 'normal'}
                    // sx={{}}
                />
            ),
        },
        // {
        //     title: 'Date Added',
        //     render: (row) => (
        //         <Stack>
        //             <Box>
        //                 {new Date(row.date_created).toLocaleDateString('en-US', {
        //                     month: 'long',
        //                     day: 'numeric',
        //                     year: 'numeric',
        //                 })}
        //             </Box>
        //             <Box>
        //                 (
        //                 {new Date(row?.date_created).toLocaleTimeString([], {
        //                     hour12: true,
        //                     hour: '2-digit',
        //                     minute: '2-digit',
        //                 })}
        //                 )
        //             </Box>
        //         </Stack>
        //     ),
        // },
        {
            title: 'Effective from',
            render: (row) => {
                if (!row?.effective_from) {
                    return <HorizontalRuleRounded color="disabled" sx={{ fontSize: 50 }} />;
                } else {
                    const [start, end] = row.effective_from.split(',');
                    const startDate = new Date(start),
                        endDate = new Date(end);
                    return (
                        <span>
                            {startDate?.toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}{' '}
                            -{' '}
                            {endDate?.toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                        </span>
                    );
                }
            },
        },
        {
            title: '',
            align: 'center',
            key: 'action',
            render: (row) => {
                // const uploadedDocs = Array.isArray(row?.uploaded_docs) ? row?.uploaded_docs : [];
                return (
                    <div className="flex items-center">
                        <IconButton
                            // className={`mr-2 ${
                            //     row.status === AUDIT_STATUS.COMPLETE ? 'cursor-pointer' : 'cursor-not-allowed'
                            // }`}
                            // href={row.status === AUDIT_STATUS.COMPLETE ? row.certification : ''}
                            // target="_blank"
                            // rel="noreferrer"
                            // disabled={row?.status !== AUDIT_STATUS.COMPLETE || row?.status !== AUDIT_STATUS.AUDITING}
                            onClick={() => openDownloadModal(row)}
                            ref={ref2}
                        >
                            <IoMdDownload
                                size="25px"
                                // color={`${row.status === 'completed' ? '#0E2C66' : '#E4E2E6'}`}
                            />
                        </IconButton>
                        {/* <AuditTableAction
                        record={row}
                        openDeleteModal={openDeleteModal}
                        openDownloadModal={openDownloadModal}
                    /> */}
                    </div>
                );
            },
        },
    ];
    const current_client = registered_clients?.find((client) => client?.id === client_id);
    const filtered_audit_requests = useMemo(() => {
        const text = query?.toLowerCase();
        return all_audit_requests?.filter(
            (request) =>
                request?.status?.toLowerCase()?.includes(text) || request?.compliance?.toLowerCase()?.includes(text)
        );
    }, [query, all_audit_requests]);

    return (
        <>
            {view === 'table' ? (
                <>
                    <AppTable
                        columns={columns}
                        data={filtered_audit_requests}
                        title={
                            <AppInput
                                placeholder="Search file..."
                                sx={{
                                    maxWidth: 200,
                                    borderColor: theme.palette.gray[30],
                                    '& input::placeholder': {
                                        color: theme.palette.gray[40],
                                        fontWeight: 600,
                                    },
                                }}
                                controlStyle={{ mt: 0, width: 'fit-content' }}
                                onChange={(event) => setQuery(event.target.value)}
                                value={query}
                            />
                        }
                        actions={[
                            (user_info?.user_type === 'implementer'
                                ? current_client?.generate_cert
                                : merchant_info?.generate_cert) && (
                                <AppButton
                                    name={'View Certificate'}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setView('certificate')}
                                    key={1}
                                />
                            ),
                        ]}
                        noPerPage={NO_PER_PAGE}
                        sorter={(a, b) => b?.id - a?.id}
                        dataLength={all_audit_requests?.length}
                        page={page}
                        loading={loading}
                        onPageChange={handlePageChange}
                        rowSpacing={true}
                        // clickableRow={(row) => [AUDIT_STATUS.DISAPPROVED, AUDIT_STATUS.DRAFT].includes(row?.status)}
                        // onRowClick={(row) => gotoPlanSettings(row?.compliance)}
                        // actions={
                        //     <Box sx={{ position: 'relative' }}>
                        //         <Button
                        //             sx={{
                        //                 display: 'flex',
                        //                 alignItems: 'center',
                        //                 border: `1.5px solid ${theme.palette.primary[900]}`,
                        //                 borderRadius: 1,
                        //                 p: 1.25,
                        //                 color: theme.palette.primary[900],
                        //                 textTransform: 'capitalize',
                        //                 fontSize: 16,
                        //                 minWidth: 180,
                        //             }}
                        //             component="button"
                        //             onClick={handleDropdownOpen}
                        //         >
                        //             <span className="pr-2 font-semibold">Generate Report</span>
                        //             <BiChevronDown className="font-bold" size="18px" />
                        //         </Button>
                        //         <Menu
                        //             anchorEl={dropdownAnchor}
                        //             open={Boolean(dropdownAnchor)}
                        //             onClose={handleDropdownClose}
                        //             anchorOrigin={{
                        //                 vertical: 'bottom',
                        //                 horizontal: 'right',
                        //             }}
                        //             transformOrigin={{
                        //                 vertical: 'top',
                        //                 horizontal: 'right',
                        //             }}
                        //             sx={{ '& ul': { minWidth: 180 } }}
                        //         >
                        //             {compliance_standards.map((standard) => {
                        //                 const loading = creatingRequest?.[standard];
                        //                 const hasRequest = all_audit_requests?.find(
                        //                     (request) => request.compliance === standard.toLowerCase()
                        //                 );
                        //                 return (
                        //                     <MenuItem
                        //                         onClick={() => gotoPlanSettings(standard)}
                        //                         sx={{
                        //                             fontSize: 16,
                        //                             fontWeight: 600,
                        //                             color: theme.palette.gray[20],
                        //                             display: 'flex',
                        //                             justifyContent: 'space-between',
                        //                         }}
                        //                         key={standard}
                        //                         disabled={loading || standard !== 'PCIDSS' || hasRequest}
                        //                     >
                        //                         <span>{standard}</span>
                        //                         {loading && <LoadingState size={20} />}
                        //                     </MenuItem>
                        //                 );
                        //             })}
                        //         </Menu>
                        //     </Box>
                        // }
                    />
                    <AuditDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} data={data} />
                    <AuditDownloadModal open={downloadModalOpen} handleClose={closeDownloadModal} data={data} />
                </>
            ) : (
                <ViewCertificate setView={setView} />
            )}

            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Audits"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};
const mapStateToProps = (state) => ({
    all_audit_requests: state?.auditReducers?.all_audit_requests,
    merchant_info: state?.merchantReducers?.merchant_info,
    userguide_info: state?.guideReducers?.userguide_info?.[0]?.audits,
    all_article_header: state?.articlesReducers?.all_article_header,
});
export default connect(mapStateToProps, { GetAllAuditRequests, GetAllAuditRequestsImplementer, CreateAuditRequest })(
    AuditTableRecord
);
