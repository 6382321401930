import React, { useState } from 'react';
import { Box, Menu, MenuItem, Typography } from '@mui/material';

//translations
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppDrawer from 'components/new_components/AppDrawer';
import SubTab from 'components/new_components/tabs/SubTab';
import moment from 'moment';
import EmptyState from 'assets/img/EmptyState.svg';
import Linkedin from 'assets/img/linkedin.svg';
import Instagram from 'assets/img/instagram.svg';
import Twitter from 'assets/img/X.svg';
import Medium from 'assets/img/medium.svg';
import Pinterest from 'assets/img/pintrest.svg';
import Hashnode from 'assets/img/hashnode.svg';
import Facebook from 'assets/img/facebook.svg';
import Tictok from 'assets/img/tictoc.svg';
import Secure from 'assets/img/secure.svg';

const VendorModal = (props) => {
    // Props
    const { open, handleClose, details, handleViewSource } = props;
    const [currentTab, setCurrentTab] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    // const theme = useTheme();

    // functions
    const openAnchor = (event) => {
        setAnchorEl(event.target);
    };

    const closeAnchor = () => {
        setAnchorEl(null);
    };

    const tabs = [
        {
            index: 0,
            label: 'Entity details',
        },
        {
            index: 1,
            label: 'Sanctions',
        },
        {
            index: 2,
            label: 'PEP',
        },
        {
            index: 3,
            label: 'Adverse Media',
        },
        {
            index: 4,
            label: 'Entity Socials',
        },
    ];
    const onClose = () => {
        setCurrentTab(0);
        handleClose();
    };

    return (
        <AppDrawer
            open={open}
            handleClose={onClose}
            title={
                <div>
                    <Typography sx={{ color: '#202D66', fontSize: '16px', fontWeight: 600 }}>Entity</Typography>
                    <p className="text-[13px] text-gray-500 font-normal">{details?.name}</p>
                </div>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            width="350px"
            sx={{ overflowY: 'scroll', overflowX: 'hidden', height: '100vh' }}
        >
            <Box
                sx={{
                    my: 2,
                }}
            >
                <SubTab tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} sx={{ mx: '1.7rem' }} />
                <Box>
                    {currentTab === 0 && (
                        <div className="mx-6 my-7">
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-medium text-[#64748B]">Name</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-normal text-[#395BA9]">
                                    {details?.name}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-medium text-[#64748B]">Date Created</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-normal text-[#395BA9]">
                                    {moment(details?.date_created).format('MMM DD, YYYY ::: hh:mma')}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-medium text-[#64748B]">Date Updated</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-normal text-[#395BA9]">
                                    {moment(details?.date_updated).format('MMM DD, YYYY ::: hh:mma')}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-medium text-[#64748B]">Search Hits</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-normal text-[#395BA9]">
                                    {details?.result?.total_hits}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-medium text-[#64748B]">RisK Level</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-normal text-[#395BA9]">
                                    {details?.result?.risk_level}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-medium text-[#64748B]">Blacklist Hits</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-1 pl-1 text-[14px] font-normal text-[#395BA9] ">
                                    <p
                                        className={`${
                                            details.result?.total_blacklist_hits > 0
                                                ? 'text-[#FF5449]'
                                                : 'text-gray-500'
                                        } border border-[#E1E2EC] w-[30px] rounded p-1  flex items-center justify-center`}
                                    >
                                        {details?.result?.total_blacklist_hits || 'N/A'}
                                    </p>
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-medium text-[#64748B]">Selected Sources</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-normal text-[#395BA9]">
                                    {details?.selected_categories?.map((item, index) => (
                                        <p
                                            className="m-1 rounded border border-[#395BA9] text-[11px] font-semibold text-[#395BA9] p-1"
                                            key={index}
                                        >
                                            {item}
                                        </p>
                                    ))}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-medium text-[#64748B]">Continous Monitoring</p>
                                <p className={`w-[60%] border-l border-[#F1F5F9] py-2 pl-1`}>
                                    <span
                                        className={`m-1 rounded border border-[#395BA9] text-xs font-medium p-1 ${
                                            details?.continous_monitoring ? 'text-[#395BA9' : 'text-[#D2A500]'
                                        }`}
                                    >
                                        {details?.continous_monitoring ? 'Available' : 'Not available'}
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}{' '}
                    {currentTab === 1 && (
                        <>
                            {details?.result?.sanction_results?.length > 0 ? (
                                <div className="m-4">
                                    {details?.result?.sanction_results?.map((sanction, index) => (
                                        <div key={index} className="rounded border border-[#F1F5F9] bg-[#F8FAFC]">
                                            <h4 className="text-sm font-semibold text-[#64748B]">{sanction.name}</h4>
                                            <p className="mt-1 text-xs font-normal text-[#64748B]">
                                                Recorded date: {sanction.recorded_date}
                                            </p>
                                            <div className="mt-1">
                                                <div className="flex items-center gap-4 border-t border-[#E2E8F0] py-1">
                                                    <p className="w-[20%] text-[13px] font-normal text-[#64748B]">
                                                        Source
                                                    </p>
                                                    <p className="w-[80%] border-l border-[#E2E8F0] pl-1 text-[13px] font-normal text-[#395BA9]">
                                                        {sanction?.source}
                                                    </p>
                                                </div>
                                                <div className="flex items-center gap-4 border-t border-[#E2E8F0] py-1">
                                                    <p className="w-[20%] text-[13px] font-normal text-[#64748B]">
                                                        Country
                                                    </p>
                                                    <p className="w-[80%] border-l border-[#E2E8F0] pl-1 text-[13px] font-normal text-[#395BA9]">
                                                        {sanction?.country}
                                                    </p>
                                                </div>
                                                <div className="flex items-center gap-4 border-t border-[#E2E8F0] py-1">
                                                    <p className="w-[20%] text-[13px] font-normal text-[#64748B]">
                                                        Types
                                                    </p>
                                                    <p className="w-[80%] border-l border-[#E2E8F0] pl-1 text-[13px] font-normal text-[#395BA9]">
                                                        {sanction?.types?.length ? (
                                                            <>
                                                                {sanction?.types?.map((type, index) => (
                                                                    <span
                                                                        key={index}
                                                                        className="mx-1 rounded border border-[#395BA9] p-1 text-[11px] font-normal text-[#395BA9]"
                                                                    >
                                                                        {type}
                                                                    </span>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="mx-[4rem] mt-[5rem] flex flex-col items-center justify-center">
                                    <img src={EmptyState} alt="EmptyState" />
                                    <p className="mt-3 text-center text-base font-medium text-[#64748B]">
                                        Sorry. No <strong>Sanction </strong> result was found for this entity
                                    </p>
                                </div>
                            )}
                        </>
                    )}{' '}
                    {currentTab === 2 && (
                        <>
                            {details?.result?.pep_results?.length > 0 ? (
                                <div className="m-4">
                                    {details?.result?.pep_results?.map((sanction, index) => (
                                        <div key={index} className="rounded border border-[#F1F5F9] bg-[#F8FAFC]">
                                            <h4 className="text-sm font-semibold text-[#64748B]">{sanction.name}</h4>
                                            <p className="mt-1 text-xs font-normal capitalize text-[#64748B]">
                                                Gender: <strong>{sanction.gender}</strong>
                                            </p>
                                            <div className="mt-1">
                                                <div className="flex items-center gap-4 border-t border-[#E2E8F0] py-1">
                                                    <p className="w-[20%] text-[13px] font-normal text-[#64748B]">
                                                        Source
                                                    </p>
                                                    <p className="w-[80%] border-l border-[#E2E8F0] pl-1 text-[13px] font-normal text-[#395BA9]">
                                                        {sanction?.source}
                                                    </p>
                                                </div>
                                                <div className="flex items-center gap-4 border-t border-[#E2E8F0] py-1">
                                                    <p className="w-[20%] text-[13px] font-normal text-[#64748B]">
                                                        Country
                                                    </p>
                                                    <p className="w-[80%] border-l border-[#E2E8F0] pl-1 text-[13px] font-normal text-[#395BA9]">
                                                        {sanction?.country}
                                                    </p>
                                                </div>
                                                <div className="flex items-center gap-4 border-t border-[#E2E8F0] py-1">
                                                    <p className="w-[20%] text-[13px] font-normal text-[#64748B]">
                                                        Types
                                                    </p>
                                                    <p className="w-[80%] border-l border-[#E2E8F0] pl-1 text-[13px] font-normal text-[#395BA9]">
                                                        {sanction?.types?.length ? (
                                                            <>
                                                                {sanction?.types?.map((type, index) => (
                                                                    <span
                                                                        key={index}
                                                                        className="tetx-[11px] mx-1 rounded border border-[#395BA9] p-1 font-normal text-[#395BA9]"
                                                                    >
                                                                        {type}
                                                                    </span>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="mx-[4rem] mt-[5rem] flex flex-col items-center justify-center">
                                    <img src={EmptyState} alt="EmptyState" />
                                    <p className="mt-3 text-center text-base font-medium text-[#64748B]">
                                        Sorry. No <strong>Pep </strong> result was found for this entity
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                    {currentTab === 3 && (
                        <>
                            {Array.isArray(details?.result?.adverse_media_results) &&
                            details?.result?.adverse_media_results?.length ? (
                                <div className="m-4">
                                    {details?.result?.adverse_media_results?.map((adverse, index) => (
                                        <div key={index}>
                                            <div onClick={openAnchor}>
                                                <div className="mb-3 cursor-pointer rounded border border-[#F1F5F9] bg-[#F8FAFC] p-3">
                                                    <h6 className="my-1 text-sm font-semibold text-[#64748B]">
                                                        {adverse?.title}
                                                    </h6>
                                                    <p className="text-[11px] font-normal text-gray-400">
                                                        Published{' '}
                                                        {moment(adverse?.date).format('MMM DD, YYYY') === 'Invalid date'
                                                            ? adverse?.date
                                                            : moment(adverse?.date).format('MMM DD, YYYY')}
                                                    </p>
                                                    <p className="my-1 text-xs font-normal text-gray-500">
                                                        {adverse?.snippet}
                                                    </p>
                                                </div>
                                            </div>
                                            <Menu
                                                open={Boolean(anchorEl)}
                                                anchorEl={anchorEl}
                                                onClose={closeAnchor}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                elevation={0}
                                            >
                                                <MenuItem
                                                    className="rounded-none border-b border-[#F1F5F9] px-4 py-2 text-sm font-medium text-[#64748B]"
                                                    key="visit"
                                                >
                                                    <a href={adverse?.url} target="_blank" rel="noreferrer">
                                                        Visit
                                                    </a>
                                                </MenuItem>
                                                {adverse.types?.length && (
                                                    <MenuItem
                                                        className="rounded-none border-b border-[#F1F5F9] px-4 py-2 text-sm font-medium text-[#64748B]"
                                                        key="view-sources"
                                                        onClick={() => handleViewSource(adverse.types)}
                                                    >
                                                        View Sources
                                                    </MenuItem>
                                                )}
                                            </Menu>
                                        </div>
                                    ))}
                                </div>
                            ) : typeof details?.result?.adverse_media_results == 'string' ? (
                                <div className="m-4">
                                    <p className="mt-3 text-base font-medium text-[#64748B]">
                                        {details?.result?.adverse_media_results}
                                    </p>
                                </div>
                            ) : (
                                <div className="mx-[4rem] mt-[5rem] flex flex-col items-center justify-center">
                                    <img src={EmptyState} alt="EmptyState" />
                                    <p className="mt-3 text-center text-base font-medium text-[#64748B]">
                                        Sorry. No <strong>Adverse media</strong> result was found for this entity
                                    </p>
                                </div>
                            )}
                        </>
                    )}{' '}
                    {currentTab === 4 && (
                        <>
                            {details?.result?.social_media?.length > 0 ? (
                                <div className="m-4">
                                    {details?.result?.social_media?.map((social, index) => (
                                        <div
                                            className="mb-3 rounded border border-[#F1F5F9] bg-[#F8FAFC] p-3"
                                            key={index}
                                        >
                                            <img
                                                src={
                                                    social.platform === 'Linkedin'
                                                        ? Linkedin
                                                        : social?.platform === 'Instagram'
                                                        ? Instagram
                                                        : social?.platform === 'Twitter'
                                                        ? Twitter
                                                        : social?.platform === 'Medium'
                                                        ? Medium
                                                        : social?.platform === 'Pintrest'
                                                        ? Pinterest
                                                        : social?.platform === 'Hashnode'
                                                        ? Hashnode
                                                        : social?.platform === 'Facebook'
                                                        ? Facebook
                                                        : social?.platform === 'Tiktok'
                                                        ? Tictok
                                                        : Secure
                                                }
                                                width={25}
                                                height={25}
                                                alt="social Icon"
                                            />
                                            <h6 className="my-1 text-sm font-semibold text-[#64748B]">
                                                {details?.name}
                                            </h6>
                                            <a
                                                href={social?.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="opacity-75 transition-opacity hover:opacity-75"
                                            >
                                                <p className="my-2 text-xs font-normal text-[#94A3B8]">{social?.bio}</p>
                                            </a>
                                            <p className="my-1 text-xs font-normal text-[#64748B]">
                                                {social?.description}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="mx-[4rem] mt-[5rem] flex flex-col items-center justify-center">
                                    <img src={EmptyState} alt="EmptyState" />
                                    <p className="mt-3 text-center text-base font-medium text-[#64748B]">
                                        Sorry. No <strong>Social Media </strong> result was found for this entity
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </AppDrawer>
    );
};

export default VendorModal;
