import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import Checkdone from 'assets/img/dashboard/CheckDone.svg';
import AppButton from 'components/new_components/AppButton';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import React, { useState } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { notifyOptions } from '../utils';
import EmployeeSelect from './EmployeeSelect';
import Stepper from './Stepper';

const StepTwo = (props) => {
    const {
        loading,
        handleRadioChange,
        handleSubmit,
        error,
        value,
        moveToStep,
        step,
        toPreviousStep,
        handleNotifyListSelect,
        notifyList,
        setNotifyList,
        isNotifyIconClicked,
    } = props;

    const theme = useTheme();

    const [employeeModal, setEmployeeModal] = useState(false);

    //  employee Modal
    const openEmployeeModal = () => {
        setEmployeeModal(true);
    };
    const closeEmployeeModal = () => {
        setEmployeeModal(false);
    };
    return (
        <>
            {!isNotifyIconClicked ? (
                <Typography
                    sx={{
                        fontSize: 11,
                        color: theme.palette.gray[40],
                        fontWeight: 400,
                        backgroundColor: '#F8F8F8',
                        px: 3,
                        py: 1,
                        borderRadius: '8px',
                        my: 2,
                        border: `1px solid ${theme.palette.neutral[95]}`,
                    }}
                >
                    There was an update to this policy by SmartcomplyApp
                </Typography>
            ) : null}
            <form onSubmit={handleSubmit}>
                <RadioInput
                    handleRadioChange={handleRadioChange}
                    error={error}
                    value={value}
                    openEmployeeModal={openEmployeeModal}
                    notifyList={notifyList}
                    isNotifyIconClicked={isNotifyIconClicked}
                />
                {isNotifyIconClicked ? (
                    <AppButton
                        name="Proceed"
                        color="primary"
                        variant="contained"
                        disableElevation
                        type="submit"
                        fullWidth
                        sx={{ mb: 3 }}
                    />
                ) : (
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
                        <Stepper currentStep={step} totalSteps={2} onClick={moveToStep} />
                        <Stack direction="row" gap={2}>
                            <AppButton
                                name="Back"
                                color="primary"
                                variant="outlined"
                                disableElevation
                                onClick={toPreviousStep}
                            />
                            <AppLoadingButton
                                name="Overwrite"
                                color="primary"
                                variant="contained"
                                disableElevation
                                type="submit"
                                loading={loading}
                                disabled={loading}
                                text="Overwrite"
                            />
                        </Stack>
                    </Stack>
                )}
            </form>
            <EmployeeSelect
                open={employeeModal}
                handleClose={closeEmployeeModal}
                handleNotifyListSelect={handleNotifyListSelect}
                notifyList={notifyList}
                setNotifyList={setNotifyList}
            />
        </>
    );
};

const RadioInput = (props) => {
    const { error, value, handleRadioChange, openEmployeeModal, notifyList, isNotifyIconClicked } = props;

    const theme = useTheme();
    return (
        <>
            <FormControl error={error} variant="standard">
                <RadioGroup name="notify" value={value} onChange={handleRadioChange}>
                    {notifyOptions?.map((option, index) => {
                        const { label } = option;
                        const boolVal = value === 'true' ? true : value === null ? '' : false;
                        return (
                            <React.Fragment key={index}>
                                <FormControlLabel
                                    value={option.value}
                                    sx={{
                                        border:
                                            option?.value !== boolVal
                                                ? `1px solid ${theme.palette.gray[95]}`
                                                : `2px solid ${theme.palette.primary[20]}`,
                                        backgroundColor: '#FEFBFF',
                                        width: '100%',
                                        m: 0,
                                        py: 2,
                                        borderRadius: '8px',
                                        mt: isNotifyIconClicked ? 1 : 3,
                                        mb: index === 1 ? 3 : 0,
                                    }}
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#707380',
                                                '&.Mui-checked': { color: theme.palette.primary[20] },
                                            }}
                                        />
                                    }
                                    label={
                                        <>
                                            <Typography
                                                sx={{ fontSize: 13, fontWeight: 600, color: theme.palette.gray[30] }}
                                            >
                                                {label.title}
                                            </Typography>
                                            <Typography
                                                sx={{ fontSize: 10, fontWeight: 400, color: theme.palette.gray[30] }}
                                            >
                                                {label.description}
                                            </Typography>
                                        </>
                                    }
                                />
                                {index === 0 && Boolean(value === 'true') ? (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ py: 1 }}
                                    >
                                        <div className="flex flex-row gap-1 items-center">
                                            <HiOutlineExclamationCircle color="#D2A500" />
                                            <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                                                All employees will be informed
                                            </Typography>
                                        </div>
                                        <AppButton
                                            name="Change settings"
                                            startIcon={
                                                notifyList?.length ? (
                                                    <img src={Checkdone} className="w-[12px] h-[12px]" alt="check" />
                                                ) : null
                                            }
                                            color="primary"
                                            variant="text"
                                            disableElevation
                                            sx={{
                                                minHeight: 'unset',
                                                p: 0,
                                                fontSize: 10,
                                                fontWeight: 500,
                                                textDecoration: 'underline',
                                            }}
                                            onClick={openEmployeeModal}
                                        />
                                    </Stack>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </RadioGroup>
                {error ? <FormHelperText>Please Select an option</FormHelperText> : null}
            </FormControl>
        </>
    );
};
export default StepTwo;
