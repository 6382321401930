import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { connect } from 'react-redux';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

import ArticleGuide from 'assets/img/AritcleGuide.svg';
import CustomTooltip from 'components/new_components/CustomTooltip';
// core components

const TipsEnableButton = () => {
    const theme = useTheme();
    const location = useLocation();

    const [showTips, setShowTips] = useState(true);

    const { toggleUserGuide, setToggleUserGuide, getAllMerchantArticles } = useContext(UserGuideContext);

    useEffect(() => {
        setTimeout(() => {
            setShowTips(false);
        }, 5000);
    }, []);

    const ToggleUserGuide = () => {
        setShowTips(false);
        const userguidePath = location.pathname.split('/');
        const currentPath = userguidePath.slice(-1);
        const compliancePath = userguidePath.slice(-2, -1);

        compliancePath[0] === 'compliance' && currentPath[0] !== 'audits' && currentPath[0] !== 'data-room'
            ? setToggleUserGuide({ ...toggleUserGuide, compliance: true })
            : currentPath[0] === 'index'
            ? setToggleUserGuide({ ...toggleUserGuide, dashboard: true })
            : currentPath[0] === 'users'
            ? setToggleUserGuide({ ...toggleUserGuide, users: true })
            : currentPath[0] === 'employee'
            ? setToggleUserGuide({ ...toggleUserGuide, employee: true })
            : currentPath[0] === 'policies'
            ? setToggleUserGuide({ ...toggleUserGuide, onboarding: true })
            : currentPath[0] === 'pciscans'
            ? setToggleUserGuide({ ...toggleUserGuide, pciscans: true })
            : currentPath[0] === 'pentest'
            ? setToggleUserGuide({ ...toggleUserGuide, pentest: true })
            : currentPath[0] === 'automated-scans'
            ? setToggleUserGuide({ ...toggleUserGuide, automatedscans: true })
            : currentPath[0] === 'people'
            ? setToggleUserGuide({ ...toggleUserGuide, employee: true })
            : currentPath[0] === 'infrastructures'
            ? setToggleUserGuide({ ...toggleUserGuide, infrastructures: true })
            : currentPath[0] === 'pcidss'
            ? setToggleUserGuide({ ...toggleUserGuide, pcidss: true })
            : currentPath[0] === 'vendors'
            ? setToggleUserGuide({ ...toggleUserGuide, vendors: true })
            : currentPath[0] === 'vulnerability'
            ? setToggleUserGuide({ ...toggleUserGuide, vulnerabilityscans: true })
            : currentPath[0] === 'risk-asessement'
            ? setToggleUserGuide({ ...toggleUserGuide, riskassessment: true })
            : currentPath[0] === 'audits'
            ? setToggleUserGuide({ ...toggleUserGuide, audits: true })
            : currentPath[0] === 'data-room'
            ? setToggleUserGuide({ ...toggleUserGuide, dataroom: true })
            : currentPath[0] === 'codebase'
            ? setToggleUserGuide({ ...toggleUserGuide, codebase_scanner: true })
            : currentPath[0] === 'computers'
            ? setToggleUserGuide({ ...toggleUserGuide, fraud_guardian: true })
            : currentPath[0] === 'training'
            ? setToggleUserGuide({ ...toggleUserGuide, employee: true })
            : currentPath[0] === 'fraud-monitoring'
            ? setToggleUserGuide({ ...toggleUserGuide, fraud_guardian: true })
            : currentPath[0] === 'integrations'
            ? setToggleUserGuide({ ...toggleUserGuide, infrastructures: true })
            : null;
    };

    return (
        <Box>
            <Tooltip
                title={
                    <Box sx={{ p: '1px' }}>
                        <Typography sx={{ fontWeight: 400, fontSize: '8px' }}>Feeling Lost?</Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>Let's guide you</Typography>
                    </Box>
                }
                arrow
                placement="bottom-end"
                open={showTips}
                disableHoverListener
                PopperProps={{
                    sx: {
                        zIndex: 801,
                        '& .MuiTooltip-arrow': {
                            color: theme.palette.secondary[500],
                        },
                        '& .MuiTooltip-tooltip': {
                            backgroundColor: theme.palette.secondary[500],
                            color: theme.palette.primary.main,
                            p: 1,
                            borderRadius: '8px',
                        },
                    },
                }}
            >
                <IconButton
                    onClick={() => {
                        ToggleUserGuide();
                        getAllMerchantArticles();
                    }}
                    sx={{ display: { xs: 'none', md: 'flex' }, mr: '10px' }}
                >
                    <CustomTooltip title="User Guide" placement="bottom">
                        <img src={ArticleGuide} alt="userguide" className="w-6 h-6" />
                    </CustomTooltip>
                </IconButton>
            </Tooltip>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.welcome,
    };
};

export default connect(mapStateToProps)(TipsEnableButton);
