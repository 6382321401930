import { Stack, Box, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import WestIcon from '@mui/icons-material/West';

// import { useTheme } from '@mui/material/styles';
import ResponseItemContainer from './Components/ResponseItemContainer';
import ResponseBody from './Components/components/ResponseBody';
import VendorScoreCard from './Components/components/VendorScoreCard';
// import ResponseHeader from './Components/components/ResponseHeader';
import { CSVLink } from 'react-csv';
import JSZip from 'jszip';
import axiosInstance from 'utils/https';
import { Loader2 } from 'lucide-react';

const VendorResponseView = (props) => {
    const merchant = useSelector((state) => state?.authReducers?.company_details);
    // const theme = useTheme();
    const {
        questionnaire_forms,
        all_responses,
        activeTab,
        setActiveTab,
        vendor,
        handleChangeView,
        goBack,
        loading = { loading },
    } = props;

    const currentResponse = useMemo(() => {
        return all_responses?.find((response) => response?.id === activeTab);
    }, [activeTab]);
    const currentQuestion = useMemo(() => {
        return questionnaire_forms?.find((question) => question?.title === currentResponse?.form_title);
    }, [currentResponse]);
    const [openScorecard, setOpenScorecard] = useState(false);
    const [zipLoading, setZipLoading] = useState(false);

    const openScoreCard = () => {
        setOpenScorecard(true);
    };

    const handleClose = () => {
        setOpenScorecard(false);
    };
    const vendorResponse = all_responses?.filter((response) => response?.vendor_details?.id === JSON?.parse(vendor));

    async function getDocumentSignedURL(documentName) {
        try {
            setZipLoading(true);
            const response = await axiosInstance().get(`/compliance/signed_url/?object_key=${documentName}`);
            setZipLoading(false);
            return response?.url;
        } catch (error) {
            setZipLoading(false);
            console.log(error);
        }
    }

    async function downloadAndZipFiles(urls, zipFileName = 'assets.zip') {
        const zip = new JSZip();

        setZipLoading(true);

        const downloadPromises = urls.map(async (url, index) => {
            try {
                const response = await fetch(url);

                if (!response.ok) throw new Error(`Failed to fetch ${url}`);

                const blob = await response.blob();
                const arrayBuffer = await blob.arrayBuffer();

                if (arrayBuffer.byteLength === 0) {
                    throw new Error(`Empty file detected: ${url}`);
                }

                // const fileName = url.split('/').pop() || `file_${index}`;
                const fileName = url.split('/').pop()?.split('?')[0] || `file_${index}`;

                zip.file(fileName, new Uint8Array(arrayBuffer));
            } catch (error) {
                setZipLoading(false);
                console.error(`Error downloading ${url}:`, error);
            }
        });

        await Promise.all(downloadPromises);

        const zipBlob = await zip.generateAsync({ type: 'blob' });

        const a = document.createElement('a');
        a.href = URL.createObjectURL(zipBlob);
        a.download = zipFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        setZipLoading(false);
    }

    const links = useMemo(() => {
        const urls = [];

        (vendorResponse || []).forEach((category) => {
            const categoryForm = questionnaire_forms?.find(
                (form) => form.title?.toLowerCase() === category?.form_title?.toLowerCase()
            );

            (categoryForm?.form_fields || [])?.forEach((field) => {
                const answer = category?.answer[field?.id] || 1;
                if (answer?.[1] && answer?.[1]?.startsWith('https://')) {
                    urls.push(answer?.[1]);
                }
            });
        });

        return urls;
    }, [vendorResponse, questionnaire_forms]);

    const responseAnswersList = useMemo(() => {
        const data = [['Question', 'Answer', 'Document']];

        (vendorResponse || []).forEach((category) => {
            const categoryForm = questionnaire_forms?.find(
                (form) => form.title?.toLowerCase() === category?.form_title?.toLowerCase()
            );

            (categoryForm?.form_fields || [])?.forEach((field) => {
                const question = field?.name?.replace(/^\d+\.?\s*/, '') || '';
                const answer = category?.answer[field?.id] || 1;

                // get link if exists + file name
                const link = answer?.[1] && answer?.[1]?.startsWith('https://') ? answer?.[1]?.split('/').pop() : '';
                data.push([question, answer?.[0], link || '']);
            });
        });

        return data;
    }, [vendorResponse, questionnaire_forms]);

    return (
        <Box sx={{ height: '100vh' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                    borderBottom: '1px solid #F1F5F9',
                    px: 2,
                    py: 1,
                    height: '7vh',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <WestIcon color="#64748B" onClick={goBack} sx={{ cursor: 'pointer' }} />
                    <Typography sx={{ color: '#202D66', fontSize: '18px', fontWeight: 700, ml: 1.5 }}>
                        Vendor response ({currentResponse?.vendor_details?.name || merchant?.name})
                    </Typography>
                </Box>

                <Stack
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                    justifyContent="end"
                    sx={{
                        '& a': {
                            textDecoration: 'none',
                            pointerEvents: zipLoading ? 'none' : 'auto',
                        },
                    }}
                >
                    {responseAnswersList?.length ? (
                        <CSVLink
                            data={responseAnswersList}
                            filename={currentResponse?.vendor_details?.name || 'vendor response.csv'}
                            target="_blank"
                            onClick={async () => {
                                if (links?.length) {
                                    try {
                                        const signed_links = await Promise.all(
                                            links.map(async (link) => {
                                                const signed_url = await getDocumentSignedURL(
                                                    link.split('.com/')?.pop()
                                                );

                                                return signed_url;
                                            })
                                        );

                                        if (signed_links.length) {
                                            downloadAndZipFiles(
                                                signed_links,
                                                currentResponse?.vendor_details?.name + '-assets'
                                            );
                                        }
                                    } catch (error) {
                                        console.error('Error generating signed links:', error);
                                    }
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    backgroundColor: '#F1F5F9',
                                    color: '#202D66',
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    textTransform: 'inherit',
                                    px: 1.5,
                                    py: 1,
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    pointerEvents: zipLoading ? 'none' : 'auto',
                                    opacity: zipLoading ? 0.7 : 1,
                                    display: 'flex',
                                }}
                            >
                                {zipLoading && <Loader2 className="mr-2 animate-spin h-4 w-4" />}
                                Export response
                            </Typography>
                        </CSVLink>
                    ) : null}
                    <Typography
                        sx={{
                            backgroundColor: '#F1F5F9',
                            color: '#202D66',
                            fontSize: '13px',
                            fontWeight: 500,
                            textTransform: 'inherit',
                            px: 1.5,
                            py: 1,
                            borderRadius: 1,
                            cursor: 'pointer',
                        }}
                        onClick={openScoreCard}
                    >
                        View score card
                    </Typography>
                </Stack>
            </Box>
            <Box
                sx={{
                    // height: 'calc(100vh - 114px)',
                    width: 'calc(100% + 48px)',
                    display: 'flex',
                    alignItems: 'center',
                    ml: '-24px',
                    // mt: '-24px',
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        width: '100%',
                        overflow: 'auto',
                        height: '93vh',
                    }}
                >
                    <Box
                        component="nav"
                        sx={{
                            width: { md: 290 },
                            flexShrink: { md: 0 },
                        }}
                    >
                        <ResponseItemContainer
                            largeScreen={true}
                            all_questions={vendorResponse}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            handleChangeView={handleChangeView}
                        />
                    </Box>
                    <Stack
                        sx={{
                            height: '90vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {/* <ResponseHeader
                            id={currentResponse?.id}
                            key={currentResponse?.id}
                            activeTab={activeTab}
                            title={currentResponse?.form_title}
                            value={currentResponse?.score_percent}
                            openDrawer={openDrawer}
                            score_percent={currentResponse?.score_percent}
                            risk_level={currentResponse?.risk_level}
                        /> */}
                        <ResponseBody
                            key={currentQuestion?.id}
                            activeTab={activeTab}
                            title={currentResponse?.form_title}
                            questionID={currentQuestion?.id}
                            question={currentQuestion}
                            answers={currentResponse?.answer}
                            score_percent={currentResponse?.score_percent}
                            risk_level={currentResponse?.risk_level}
                        />
                    </Stack>
                </Stack>
                <VendorScoreCard
                    open={openScorecard}
                    handleClose={handleClose}
                    vendor={vendor}
                    questionnaire_forms={questionnaire_forms}
                />
            </Box>
        </Box>
    );
};

export default VendorResponseView;
