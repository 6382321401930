import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Menu, MenuItem } from '@mui/material';
import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import Carousel from 'components/new_components/carousel/Carousel';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetOverview } from 'store/actions/riskAssessmentActions';
import RiskHeat from './RiskHeat';

const RiskDistribution = ({ switch_on, handleOpen }) => {
    const [matrixEl, setMatrixEl] = useState(null);
    const [risk_levelEl, setRisk_levelEl] = useState(null);
    const [matrix, setMatrix] = useState('5x5');
    const [risk_level, setRisk_level] = useState('inherent');
    const [loadingDatas, setLoadingDatas] = useState(false);
    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    const dispatch = useDispatch();
    // const { switch_on, handleOpen } = props;
    const getRiskOverview = async () => {
        setLoadingDatas(true);
        await dispatch(GetOverview(matrix));
        setLoadingDatas(false);
    };

    useEffect(() => {
        getRiskOverview();
    }, [matrix]);

    const keywords = merchant_info?.intel_keywords?.['Risk Distribution'];

    const intel =
        switch_on && all_smart_intel?.['Risk Distribution']
            ? Object?.values(all_smart_intel?.['Risk Distribution'])
                  ?.map((option) => option)
                  ?.flat()
                  ?.filter((el) => {
                      return keywords?.some(
                          (keyword) =>
                              el?.title?.toLowerCase()?.includes(keyword) ||
                              el?.market_place?.toLowerCase()?.includes(keyword)
                      );
                  })
            : [];

    return (
        <div className="bg-white rounded-md h-full flex flex-col p-3">
            <div className="flex justify-between items-center">
                <h4 className="text-[#2B3674] text-lg font-semibold">Risk Distribution</h4>
                <div className="flex items-center gap-3">
                    <div>
                        <button
                            aria-controls={matrixEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={matrixEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setMatrixEl(event.currentTarget);
                            }}
                            className="bg-[#F8FAFC] !border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1 !px-2 transition-colors"
                        >
                            <p className="text-[#64748B] font-medium text-[13px]">{matrix}</p>

                            <ExpandMoreIcon sx={{ color: '#94A3B8' }} />
                        </button>

                        <Menu
                            anchorEl={matrixEl}
                            open={Boolean(matrixEl)}
                            onClose={() => setMatrixEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow:
                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <MenuItem
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: '1px solid #F1F5F9',
                                    padding: '8px 16px',
                                }}
                                onClick={() => {
                                    setMatrix('3x3');
                                    setMatrixEl(null);
                                }}
                            >
                                <p className="text-[#64748B] font-medium text-sm">3x3</p>
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    padding: '8px 16px',
                                }}
                                onClick={() => {
                                    setMatrix('5x5');
                                    setMatrixEl(null);
                                }}
                            >
                                <p className="text-[#64748B] font-medium text-sm">5x5</p>
                            </MenuItem>
                        </Menu>
                    </div>
                    <div>
                        <button
                            aria-controls={risk_level ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={risk_levelEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setRisk_levelEl(event.currentTarget);
                            }}
                            className="bg-[#F8FAFC] !border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1 !px-2 transition-colors"
                        >
                            <p className="text-[#64748B] font-medium text-[13px]">{risk_level}</p>

                            <ExpandMoreIcon sx={{ color: '#94A3B8' }} />
                        </button>

                        <Menu
                            anchorEl={risk_levelEl}
                            open={Boolean(risk_levelEl)}
                            onClose={() => setRisk_levelEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow:
                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <MenuItem
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: '1px solid #F1F5F9',
                                    padding: '8px 16px',
                                }}
                                onClick={() => {
                                    setRisk_level('inherent');
                                    setRisk_levelEl(null);
                                }}
                            >
                                <p className="text-[#64748B] font-medium text-sm">Inherent</p>
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    padding: '8px 16px',
                                }}
                                onClick={() => {
                                    setRisk_level('residual');
                                    setRisk_levelEl(null);
                                }}
                            >
                                <p className="text-[#64748B] font-medium text-sm">Residual</p>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
            {switch_on && intel?.length ? (
                <div className=" my-[1.5rem] transition ease-in-out duration-500">
                    <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center w-full 2xl:h-[50px] xl:h-[70px] md:[100px]">
                        <div className="flex items-center gap-2 w-[85%]">
                            <img src={SmartIntelLogo} alt="SmartIntelLogo" className="xl:block md:hidden" />
                            <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0.5 w-fit xl:block md:hidden">
                                {intel?.length}
                            </p>
                            <Carousel
                                slides={intel}
                                handleOpen={handleOpen}
                                type="Risk Distribution"
                                data={all_smart_intel?.['Risk Distribution']}
                            />
                        </div>
                        <img src={ArrowRight} alt="ArrowRight" />
                    </div>
                </div>
            ) : null}
            <div className="relative flex-1 flex items-end">
                <RiskHeat matrix={matrix} risk_level={risk_level} />
                {loadingDatas && <Loader2 className="absolute top-[30%] left-[50%] h-20 w-20 animate-spin" />}
            </div>
        </div>
    );
};

export default RiskDistribution;
