import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

const SubTab = (props) => {
    const { tabs, currentTab, setCurrentTab, sx } = props;
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4, ...sx }}>
                {tabs?.map((tab) => (
                    <Box key={tab.index} sx={{ cursor: 'pointer' }}>
                        <Typography
                            sx={{
                                color: currentTab === tab.index ? '#202D66' : '#94A3B8',
                                fontSize: '14px',
                                fontWeight: 500,
                                textAlign: 'right',
                                cursor: 'pointer',
                                mt: currentTab === tab.index ? 0 : -0.5,
                            }}
                            onClick={() => setCurrentTab(tab.index)}
                        >
                            {tab.label}
                        </Typography>
                        {currentTab === tab.index && (
                            <Box
                                sx={{
                                    backgroundColor: '#202D66',
                                    width: '100%',
                                    mt: 0.4,
                                    pt: 0.5,
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                }}
                            ></Box>
                        )}
                    </Box>
                ))}
            </Box>
            <Divider sx={{ mx: '-3.5rem' }} />
        </Box>
    );
};

export default SubTab;
