/* eslint-disable no-unused-vars */

import { MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';

export default function RiskAssessmentSettingsRiskMatrixTab({ riskMatrixTabStates, setRiskMatrixTabStates }) {
    function updateRisk5x5MatrixColumnLabel(columnIndex, newLabel) {
        setRiskMatrixTabStates({
            ...riskMatrixTabStates,
            risk5x5Matrix: {
                ...riskMatrixTabStates.risk5x5Matrix,
                columnLabels: {
                    ...riskMatrixTabStates.risk5x5Matrix.columnLabels,
                    [columnIndex]: newLabel,
                },
            },
        });
    }

    function updateRisk5x5MatrixRowLabel(rowIndex, newLabel) {
        setRiskMatrixTabStates({
            ...riskMatrixTabStates,
            risk5x5Matrix: {
                ...riskMatrixTabStates.risk5x5Matrix,
                rowLabels: {
                    ...riskMatrixTabStates.risk5x5Matrix.rowLabels,
                    [rowIndex]: newLabel,
                },
            },
        });
    }

    function updateRisk5x5MatrixData(index, newValue) {
        setRiskMatrixTabStates({
            ...riskMatrixTabStates,
            risk5x5Matrix: {
                ...riskMatrixTabStates.risk5x5Matrix,
                data: {
                    ...riskMatrixTabStates.risk5x5Matrix.data,
                    [index]: newValue,
                },
            },
        });
    }

    function updateRisk3x3MatrixColumnLabel(columnIndex, newLabel) {
        setRiskMatrixTabStates({
            ...riskMatrixTabStates,
            risk3x3Matrix: {
                ...riskMatrixTabStates.risk3x3Matrix,
                columnLabels: {
                    ...riskMatrixTabStates.risk3x3Matrix.columnLabels,
                    [columnIndex]: newLabel,
                },
            },
        });
    }

    function updateRisk3x3MatrixRowLabel(rowIndex, newLabel) {
        setRiskMatrixTabStates({
            ...riskMatrixTabStates,
            risk3x3Matrix: {
                ...riskMatrixTabStates.risk3x3Matrix,
                rowLabels: {
                    ...riskMatrixTabStates.risk3x3Matrix.rowLabels,
                    [rowIndex]: newLabel,
                },
            },
        });
    }

    function updateRisk3x3MatrixData(index, newValue) {
        setRiskMatrixTabStates({
            ...riskMatrixTabStates,
            risk3x3Matrix: {
                ...riskMatrixTabStates.risk3x3Matrix,
                data: {
                    ...riskMatrixTabStates.risk3x3Matrix.data,
                    [index]: newValue,
                },
            },
        });
    }

    return (
        <div className="bg-white rounded-lg max-w-[873px] w-full mx-auto">
            <div className="border-b flex items-center justify-between !border-[#E2E8F0] !p-6">
                <div className="w-[55%] flex flex-col gap-1">
                    <h3 className="text-[#395BA9] font-semibold">Customize risk likelihood & impact label</h3>

                    <p className="text-[#64748B] text-sm">Create custom labels for risk likelihood and impact.</p>
                </div>
            </div>

            <FiveByFiveMatrix
                risk5x5Matrix={riskMatrixTabStates.risk5x5Matrix}
                updateRisk5x5MatrixColumnLabel={updateRisk5x5MatrixColumnLabel}
                updateRisk5x5MatrixRowLabel={updateRisk5x5MatrixRowLabel}
                updateRisk5x5MatrixData={updateRisk5x5MatrixData}
            />

            <ThreeByThreeMatrix
                risk3x3Matrix={riskMatrixTabStates.risk3x3Matrix}
                updateRisk3x3MatrixColumnLabel={updateRisk3x3MatrixColumnLabel}
                updateRisk3x3MatrixRowLabel={updateRisk3x3MatrixRowLabel}
                updateRisk3x3MatrixData={updateRisk3x3MatrixData}
            />
        </div>
    );
}

function FiveByFiveMatrix({
    risk5x5Matrix,
    updateRisk5x5MatrixColumnLabel,
    updateRisk5x5MatrixRowLabel,
    updateRisk5x5MatrixData,
}) {
    const [localColumnLabels, setLocalColumnLabels] = useState({
        0: 'Insignificant',
        1: 'Minor',
        2: 'Significant',
        3: 'Major',
        4: 'Severe',
    });
    const [localRowLabels, setLocalRowLabels] = useState({
        0: 'Rare',
        1: 'Unlikely',
        2: 'Moderate',
        3: 'Likely',
        4: 'Almost Certain',
    });

    useEffect(() => {
        setLocalColumnLabels(risk5x5Matrix.columnLabels);
        setLocalRowLabels(risk5x5Matrix.rowLabels);
    }, [risk5x5Matrix]);

    return (
        <div className="!py-6 !px-4 flex flex-col gap-4">
            <h3 className="text-[#395BA9] font-bold text-[15px]">5x5 Matrix</h3>

            <div className="flex flex-col border !border-[#F1F5F9] rounded !py-3 !px-5 gap-8">
                <p className="text-[#395BA9] font-medium text-sm">
                    Click on any label to edit the caption (25 characters max)
                </p>

                <div className="grid grid-cols-6 gap-1.5 relative !pl-7 !pb-7">
                    <p className="absolute text-[#475569] font-semibold text-sm -left-6 top-[32.5%] rotate-90 -scale-[1]">
                        Likelihood
                    </p>

                    <p className="absolute text-[#475569] font-semibold text-sm left-[47.5%] bottom-0">Impact</p>

                    <div className="flex flex-col-reverse gap-1.5">
                        <div className="flex-1" />

                        {Object.values(localRowLabels).map((label, index) => (
                            <div key={index} className="flex-1 !rounded-sm">
                                <div className="grid place-items-center h-10 hover:bg-black/10 focus-within:bg-black/10 transition-colors">
                                    <input
                                        type="text"
                                        value={label}
                                        onChange={(event) => {
                                            if (event.target.value.length > 25) return;

                                            setLocalRowLabels((prev) => ({
                                                ...prev,
                                                [index]: event.target.value,
                                            }));
                                        }}
                                        onBlur={(event) => {
                                            if (event.target.value.length > 25) return;

                                            updateRisk5x5MatrixRowLabel(index, event.target.value);
                                        }}
                                        className="text-xs font-medium text-[#64748B] text-center !w-full focus-visible:outline-none bg-transparent"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-col-reverse gap-1.5">
                        <div className="flex-1 !rounded-sm">
                            <div className="grid place-items-center h-10 hover:bg-black/10 focus-within:bg-black/10 transition-colors">
                                <input
                                    type="text"
                                    value={localColumnLabels['0']}
                                    onChange={(event) => {
                                        if (event.target.value.length > 25) return;

                                        setLocalColumnLabels((prev) => ({
                                            ...prev,
                                            0: event.target.value,
                                        }));
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value.length > 25) return;

                                        updateRisk5x5MatrixColumnLabel(0, event.target.value);
                                    }}
                                    className="text-xs font-medium text-[#64748B] text-center !w-full focus-visible:outline-none bg-transparent"
                                />
                            </div>
                        </div>

                        {Object.values(risk5x5Matrix.data)
                            .slice(0, 5)
                            .map((riskLevel, index) => (
                                <div
                                    key={index}
                                    className={`flex-1 !rounded-sm hover:bg-[#00000050] transition-colors ${
                                        riskLevel.toLowerCase() === 'very_low'
                                            ? 'bg-[#006C46]'
                                            : riskLevel.toLowerCase() === 'low'
                                            ? 'bg-[#55BE8B]'
                                            : riskLevel.toLowerCase() === 'medium'
                                            ? 'bg-[#F6EB50]'
                                            : riskLevel.toLowerCase() === 'high'
                                            ? 'bg-[#DE3730]'
                                            : 'bg-[#93000A]'
                                    }`}
                                >
                                    <div className="h-10">
                                        <Select
                                            value={riskLevel}
                                            onChange={(event) => {
                                                updateRisk5x5MatrixData(index, event.target.value);
                                            }}
                                            renderValue={(value) => (
                                                <p
                                                    className={`font-medium text-[13px] grid place-items-center w-full h-full ${
                                                        riskLevel.toLowerCase() === 'medium'
                                                            ? 'text-[#475569]'
                                                            : 'text-[#fff]'
                                                    }`}
                                                >
                                                    {value.replace('_', ' ')}
                                                </p>
                                            )}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                '& .MuiSelect-select': {
                                                    minHeight: 'auto',
                                                    height: '100%',
                                                    width: '100%',
                                                    padding: '0px',
                                                    paddingRight: '0px !important',
                                                    border: 'none',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            }}
                                            IconComponent={() => null}
                                        >
                                            <MenuItem
                                                value="very_low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Very Low
                                            </MenuItem>

                                            <MenuItem
                                                value="low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Low
                                            </MenuItem>

                                            <MenuItem
                                                value="medium"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Medium
                                            </MenuItem>

                                            <MenuItem
                                                value="high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                High
                                            </MenuItem>

                                            <MenuItem
                                                value="very_high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Very High
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="flex flex-col-reverse gap-1.5">
                        <div className="flex-1 !rounded-sm">
                            <div className="grid place-items-center h-10 hover:bg-black/10 focus-within:bg-black/10 transition-colors">
                                <input
                                    type="text"
                                    value={localColumnLabels['1']}
                                    onChange={(event) => {
                                        if (event.target.value.length > 25) return;

                                        setLocalColumnLabels((prev) => ({
                                            ...prev,
                                            1: event.target.value,
                                        }));
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value.length > 25) return;

                                        updateRisk5x5MatrixColumnLabel(1, event.target.value);
                                    }}
                                    className="text-xs font-medium text-[#64748B] text-center !w-full focus-visible:outline-none bg-transparent"
                                />
                            </div>
                        </div>
                        {Object.values(risk5x5Matrix.data)
                            .slice(5, 10)
                            .map((riskLevel, index) => (
                                <div
                                    key={index}
                                    className={`flex-1 !rounded-sm hover:bg-[#00000050] transition-colors ${
                                        riskLevel.toLowerCase() === 'very_low'
                                            ? 'bg-[#006C46]'
                                            : riskLevel.toLowerCase() === 'low'
                                            ? 'bg-[#55BE8B]'
                                            : riskLevel.toLowerCase() === 'medium'
                                            ? 'bg-[#F6EB50]'
                                            : riskLevel.toLowerCase() === 'high'
                                            ? 'bg-[#DE3730]'
                                            : 'bg-[#93000A]'
                                    }`}
                                >
                                    <div className="h-10">
                                        <Select
                                            value={riskLevel}
                                            onChange={(event) => {
                                                updateRisk5x5MatrixData(index + 5, event.target.value);
                                            }}
                                            renderValue={(value) => (
                                                <p
                                                    className={`font-medium text-[13px] grid place-items-center w-full h-full ${
                                                        riskLevel.toLowerCase() === 'medium'
                                                            ? 'text-[#475569]'
                                                            : 'text-[#fff]'
                                                    }`}
                                                >
                                                    {value.replace('_', ' ')}
                                                </p>
                                            )}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                '& .MuiSelect-select': {
                                                    minHeight: 'auto',
                                                    height: '100%',
                                                    width: '100%',
                                                    padding: '0px',
                                                    paddingRight: '0px !important',
                                                    border: 'none',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            }}
                                            IconComponent={() => null}
                                        >
                                            <MenuItem
                                                value="very_low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Very Low
                                            </MenuItem>

                                            <MenuItem
                                                value="low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Low
                                            </MenuItem>

                                            <MenuItem
                                                value="medium"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Medium
                                            </MenuItem>

                                            <MenuItem
                                                value="high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                High
                                            </MenuItem>

                                            <MenuItem
                                                value="very_high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Very High
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="flex flex-col-reverse gap-1.5">
                        <div className="flex-1 !rounded-sm">
                            <div className="grid place-items-center h-10 hover:bg-black/10 focus-within:bg-black/10 transition-colors">
                                <input
                                    type="text"
                                    value={localColumnLabels['2']}
                                    onChange={(event) => {
                                        if (event.target.value.length > 25) return;

                                        setLocalColumnLabels((prev) => ({
                                            ...prev,
                                            2: event.target.value,
                                        }));
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value.length > 25) return;

                                        updateRisk5x5MatrixColumnLabel(2, event.target.value);
                                    }}
                                    className="text-xs font-medium text-[#64748B] text-center !w-full focus-visible:outline-none bg-transparent"
                                />
                            </div>
                        </div>

                        {Object.values(risk5x5Matrix.data)
                            .slice(10, 15)
                            .map((riskLevel, index) => (
                                <div
                                    key={index}
                                    className={`flex-1 !rounded-sm hover:bg-[#00000050] transition-colors ${
                                        riskLevel.toLowerCase() === 'very_low'
                                            ? 'bg-[#006C46]'
                                            : riskLevel.toLowerCase() === 'low'
                                            ? 'bg-[#55BE8B]'
                                            : riskLevel.toLowerCase() === 'medium'
                                            ? 'bg-[#F6EB50]'
                                            : riskLevel.toLowerCase() === 'high'
                                            ? 'bg-[#DE3730]'
                                            : 'bg-[#93000A]'
                                    }`}
                                >
                                    <div className="h-10">
                                        <Select
                                            value={riskLevel}
                                            onChange={(event) => {
                                                updateRisk5x5MatrixData(index + 10, event.target.value);
                                            }}
                                            renderValue={(value) => (
                                                <p
                                                    className={`font-medium text-[13px] grid place-items-center w-full h-full ${
                                                        riskLevel.toLowerCase() === 'medium'
                                                            ? 'text-[#475569]'
                                                            : 'text-[#fff]'
                                                    }`}
                                                >
                                                    {value.replace('_', ' ')}
                                                </p>
                                            )}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                '& .MuiSelect-select': {
                                                    minHeight: 'auto',
                                                    height: '100%',
                                                    width: '100%',
                                                    padding: '0px',
                                                    paddingRight: '0px !important',
                                                    border: 'none',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            }}
                                            IconComponent={() => null}
                                        >
                                            <MenuItem
                                                value="very_low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Very Low
                                            </MenuItem>

                                            <MenuItem
                                                value="low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Low
                                            </MenuItem>

                                            <MenuItem
                                                value="medium"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Medium
                                            </MenuItem>

                                            <MenuItem
                                                value="high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                High
                                            </MenuItem>

                                            <MenuItem
                                                value="very_high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Very High
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="flex flex-col-reverse gap-1.5">
                        <div className="flex-1 !rounded-sm">
                            <div className="grid place-items-center h-10 hover:bg-black/10 focus-within:bg-black/10 transition-colors">
                                <input
                                    type="text"
                                    value={localColumnLabels['3']}
                                    onChange={(event) => {
                                        if (event.target.value.length > 25) return;

                                        setLocalColumnLabels((prev) => ({
                                            ...prev,
                                            3: event.target.value,
                                        }));
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value.length > 25) return;

                                        updateRisk5x5MatrixColumnLabel(3, event.target.value);
                                    }}
                                    className="text-xs font-medium text-[#64748B] text-center !w-full focus-visible:outline-none bg-transparent"
                                />
                            </div>
                        </div>
                        {Object.values(risk5x5Matrix.data)
                            .slice(15, 20)
                            .map((riskLevel, index) => (
                                <div
                                    key={index}
                                    className={`flex-1 !rounded-sm hover:bg-[#00000050] transition-colors ${
                                        riskLevel.toLowerCase() === 'very_low'
                                            ? 'bg-[#006C46]'
                                            : riskLevel.toLowerCase() === 'low'
                                            ? 'bg-[#55BE8B]'
                                            : riskLevel.toLowerCase() === 'medium'
                                            ? 'bg-[#F6EB50]'
                                            : riskLevel.toLowerCase() === 'high'
                                            ? 'bg-[#DE3730]'
                                            : 'bg-[#93000A]'
                                    }`}
                                >
                                    <div className="h-10">
                                        <Select
                                            value={riskLevel}
                                            onChange={(event) => {
                                                updateRisk5x5MatrixData(index + 15, event.target.value);
                                            }}
                                            renderValue={(value) => (
                                                <p
                                                    className={`font-medium text-[13px] grid place-items-center w-full h-full ${
                                                        riskLevel.toLowerCase() === 'medium'
                                                            ? 'text-[#475569]'
                                                            : 'text-[#fff]'
                                                    }`}
                                                >
                                                    {value.replace('_', ' ')}
                                                </p>
                                            )}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                '& .MuiSelect-select': {
                                                    minHeight: 'auto',
                                                    height: '100%',
                                                    width: '100%',
                                                    padding: '0px',
                                                    paddingRight: '0px !important',
                                                    border: 'none',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            }}
                                            IconComponent={() => null}
                                        >
                                            <MenuItem
                                                value="very_low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Very Low
                                            </MenuItem>

                                            <MenuItem
                                                value="low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Low
                                            </MenuItem>

                                            <MenuItem
                                                value="medium"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Medium
                                            </MenuItem>

                                            <MenuItem
                                                value="high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                High
                                            </MenuItem>

                                            <MenuItem
                                                value="very_high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Very High
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="flex flex-col-reverse gap-1.5">
                        <div className="flex-1 !rounded-sm">
                            <div className="grid place-items-center h-10 hover:bg-black/10 focus-within:bg-black/10 transition-colors">
                                <input
                                    type="text"
                                    value={localColumnLabels['4']}
                                    onChange={(event) => {
                                        if (event.target.value.length > 25) return;

                                        setLocalColumnLabels((prev) => ({
                                            ...prev,
                                            4: event.target.value,
                                        }));
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value.length > 25) return;

                                        updateRisk5x5MatrixColumnLabel(4, event.target.value);
                                    }}
                                    className="text-xs font-medium text-[#64748B] text-center !w-full focus-visible:outline-none bg-transparent"
                                />
                            </div>
                        </div>

                        {Object.values(risk5x5Matrix.data)
                            .slice(20, 25)
                            .map((riskLevel, index) => (
                                <div
                                    key={index}
                                    className={`flex-1 !rounded-sm hover:bg-[#00000050] transition-colors ${
                                        riskLevel.toLowerCase() === 'very_low'
                                            ? 'bg-[#006C46]'
                                            : riskLevel.toLowerCase() === 'low'
                                            ? 'bg-[#55BE8B]'
                                            : riskLevel.toLowerCase() === 'medium'
                                            ? 'bg-[#F6EB50]'
                                            : riskLevel.toLowerCase() === 'high'
                                            ? 'bg-[#DE3730]'
                                            : 'bg-[#93000A]'
                                    }`}
                                >
                                    <div className="h-10">
                                        <Select
                                            value={riskLevel}
                                            onChange={(event) => {
                                                updateRisk5x5MatrixData(index + 20, event.target.value);
                                            }}
                                            renderValue={(value) => (
                                                <p
                                                    className={`font-medium text-[13px] grid place-items-center w-full h-full ${
                                                        riskLevel.toLowerCase() === 'medium'
                                                            ? 'text-[#475569]'
                                                            : 'text-[#fff]'
                                                    }`}
                                                >
                                                    {value.replace('_', ' ')}
                                                </p>
                                            )}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                '& .MuiSelect-select': {
                                                    minHeight: 'auto',
                                                    height: '100%',
                                                    width: '100%',
                                                    padding: '0px',
                                                    paddingRight: '0px !important',
                                                    border: 'none',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            }}
                                            IconComponent={() => null}
                                        >
                                            <MenuItem
                                                value="very_low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Very Low
                                            </MenuItem>

                                            <MenuItem
                                                value="low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Low
                                            </MenuItem>

                                            <MenuItem
                                                value="medium"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Medium
                                            </MenuItem>

                                            <MenuItem
                                                value="high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                High
                                            </MenuItem>

                                            <MenuItem
                                                value="very_high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Very High
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function ThreeByThreeMatrix({
    risk3x3Matrix,
    updateRisk3x3MatrixColumnLabel,
    updateRisk3x3MatrixRowLabel,
    updateRisk3x3MatrixData,
}) {
    const [localColumnLabels, setLocalColumnLabels] = useState({
        0: 'Insigificant',
        1: 'Minor',
        2: 'Insigificant',
    });
    const [localRowLabels, setLocalRowLabels] = useState({
        0: 'Rare',
        1: 'Unlikely',
        2: 'Moderately',
    });

    useEffect(() => {
        setLocalColumnLabels(risk3x3Matrix.columnLabels);
        setLocalRowLabels(risk3x3Matrix.rowLabels);
    }, [risk3x3Matrix]);

    return (
        <div className="!py-6 !px-4 flex flex-col gap-4">
            <h3 className="text-[#395BA9] font-bold text-[15px]">3x3 Matrix</h3>

            <div className="flex flex-col border !border-[#F1F5F9] rounded !py-3 !px-5 gap-8">
                <p className="text-[#395BA9] font-medium text-sm">Click on any label to edit the caption</p>

                <div className="grid grid-cols-[repeat(4,minmax(0,136px))] gap-1.5 relative !pl-7 !pb-7">
                    <p className="absolute text-[#475569] font-semibold text-sm -left-6 top-[32.5%] rotate-90 -scale-[1]">
                        Likelihood
                    </p>

                    <p className="absolute text-[#475569] font-semibold text-sm left-[37.5%] bottom-0">Impact</p>

                    <div className="flex flex-col-reverse gap-1.5">
                        <div className="flex-1" />

                        {Object.values(localRowLabels).map((label, index) => (
                            <div key={index} className="flex-1 !rounded-sm">
                                <div className="grid place-items-center h-[70px] hover:bg-black/10 focus-within:bg-black/10 transition-colors">
                                    <input
                                        type="text"
                                        value={label}
                                        onChange={(event) => {
                                            if (event.target.value.length > 25) return;

                                            setLocalRowLabels((prev) => ({
                                                ...prev,
                                                [index]: event.target.value,
                                            }));
                                        }}
                                        onBlur={(event) => {
                                            if (event.target.value.length > 25) return;

                                            updateRisk3x3MatrixRowLabel(index, event.target.value);
                                        }}
                                        className="text-xs font-medium text-[#64748B] text-center !w-full focus-visible:outline-none bg-transparent"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-col-reverse gap-1.5">
                        <div className="flex-1 !rounded-sm">
                            <div className="grid place-items-center h-[70px] hover:bg-black/10 focus-within:bg-black/10 transition-colors">
                                <input
                                    type="text"
                                    value={localColumnLabels['0']}
                                    onChange={(event) => {
                                        if (event.target.value.length > 25) return;

                                        setLocalColumnLabels((prev) => ({
                                            ...prev,
                                            0: event.target.value,
                                        }));
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value.length > 25) return;

                                        updateRisk3x3MatrixColumnLabel(0, event.target.value);
                                    }}
                                    className="text-xs font-medium text-[#64748B] text-center !w-full focus-visible:outline-none bg-transparent"
                                />
                            </div>
                        </div>

                        {Object.values(risk3x3Matrix.data)
                            .slice(0, 3)
                            .map((riskLevel, index) => (
                                <div
                                    key={index}
                                    className={`flex-1 !rounded-sm hover:bg-[#00000050] transition-colors ${
                                        riskLevel.toLowerCase() === 'low'
                                            ? 'bg-[#55BE8B]'
                                            : riskLevel.toLowerCase() === 'medium'
                                            ? 'bg-[#F6EB50]'
                                            : 'bg-[#DE3730]'
                                    }`}
                                >
                                    <div className="h-[70px]">
                                        <Select
                                            value={riskLevel}
                                            onChange={(event) => {
                                                updateRisk3x3MatrixData(index, event.target.value);
                                            }}
                                            renderValue={(value) => (
                                                <p
                                                    className={`font-medium text-[13px] grid place-items-center w-full h-full ${
                                                        riskLevel.toLowerCase() === 'medium'
                                                            ? 'text-[#475569]'
                                                            : 'text-[#fff]'
                                                    }`}
                                                >
                                                    {value.replace('_', ' ')}
                                                </p>
                                            )}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                '& .MuiSelect-select': {
                                                    minHeight: 'auto',
                                                    height: '100%',
                                                    width: '100%',
                                                    padding: '0px',
                                                    paddingRight: '0px !important',
                                                    border: 'none',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            }}
                                            IconComponent={() => null}
                                        >
                                            <MenuItem
                                                value="low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Low
                                            </MenuItem>

                                            <MenuItem
                                                value="medium"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Medium
                                            </MenuItem>

                                            <MenuItem
                                                value="high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                High
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="flex flex-col-reverse gap-1.5">
                        <div className="flex-1 !rounded-sm">
                            <div className="grid place-items-center h-[70px] hover:bg-black/10 focus-within:bg-black/10 transition-colors">
                                <input
                                    type="text"
                                    value={localColumnLabels['1']}
                                    onChange={(event) => {
                                        if (event.target.value.length > 25) return;

                                        setLocalColumnLabels((prev) => ({
                                            ...prev,
                                            1: event.target.value,
                                        }));
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value.length > 25) return;

                                        updateRisk3x3MatrixColumnLabel(1, event.target.value);
                                    }}
                                    className="text-xs font-medium text-[#64748B] text-center !w-full focus-visible:outline-none bg-transparent"
                                />
                            </div>
                        </div>
                        {Object.values(risk3x3Matrix.data)
                            .slice(3, 6)
                            .map((riskLevel, index) => (
                                <div
                                    key={index}
                                    className={`flex-1 !rounded-sm hover:bg-[#00000050] transition-colors ${
                                        riskLevel.toLowerCase() === 'low'
                                            ? 'bg-[#55BE8B]'
                                            : riskLevel.toLowerCase() === 'medium'
                                            ? 'bg-[#F6EB50]'
                                            : 'bg-[#DE3730]'
                                    }`}
                                >
                                    <div className="h-[70px]">
                                        <Select
                                            value={riskLevel}
                                            onChange={(event) => {
                                                updateRisk3x3MatrixData(index + 3, event.target.value);
                                            }}
                                            renderValue={(value) => (
                                                <p
                                                    className={`font-medium text-[13px] grid place-items-center w-full h-full ${
                                                        riskLevel.toLowerCase() === 'medium'
                                                            ? 'text-[#475569]'
                                                            : 'text-[#fff]'
                                                    }`}
                                                >
                                                    {value.replace('_', ' ')}
                                                </p>
                                            )}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                '& .MuiSelect-select': {
                                                    minHeight: 'auto',
                                                    height: '100%',
                                                    width: '100%',
                                                    padding: '0px',
                                                    paddingRight: '0px !important',
                                                    border: 'none',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            }}
                                            IconComponent={() => null}
                                        >
                                            <MenuItem
                                                value="low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Low
                                            </MenuItem>

                                            <MenuItem
                                                value="medium"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Medium
                                            </MenuItem>

                                            <MenuItem
                                                value="high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                High
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="flex flex-col-reverse gap-1.5">
                        <div className="flex-1 !rounded-sm">
                            <div className="grid place-items-center h-[70px] hover:bg-black/10 focus-within:bg-black/10 transition-colors">
                                <input
                                    type="text"
                                    value={localColumnLabels['2']}
                                    onChange={(event) => {
                                        if (event.target.value.length > 25) return;

                                        setLocalColumnLabels((prev) => ({
                                            ...prev,
                                            2: event.target.value,
                                        }));
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value.length > 25) return;

                                        updateRisk3x3MatrixColumnLabel(2, event.target.value);
                                    }}
                                    className="text-xs font-medium text-[#64748B] text-center !w-full focus-visible:outline-none bg-transparent"
                                />
                            </div>
                        </div>

                        {Object.values(risk3x3Matrix.data)
                            .slice(6, 9)
                            .map((riskLevel, index) => (
                                <div
                                    key={index}
                                    className={`flex-1 !rounded-sm hover:bg-[#00000050] transition-colors ${
                                        riskLevel.toLowerCase() === 'low'
                                            ? 'bg-[#55BE8B]'
                                            : riskLevel.toLowerCase() === 'medium'
                                            ? 'bg-[#F6EB50]'
                                            : 'bg-[#DE3730]'
                                    }`}
                                >
                                    <div className="h-[70px]">
                                        <Select
                                            value={riskLevel}
                                            onChange={(event) => {
                                                updateRisk3x3MatrixData(index + 6, event.target.value);
                                            }}
                                            renderValue={(value) => (
                                                <p
                                                    className={`font-medium text-[13px] grid place-items-center w-full h-full ${
                                                        riskLevel.toLowerCase() === 'medium'
                                                            ? 'text-[#475569]'
                                                            : 'text-[#fff]'
                                                    }`}
                                                >
                                                    {value.replace('_', ' ')}
                                                </p>
                                            )}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                '& .MuiSelect-select': {
                                                    minHeight: 'auto',
                                                    height: '100%',
                                                    width: '100%',
                                                    padding: '0px',
                                                    paddingRight: '0px !important',
                                                    border: 'none',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            }}
                                            IconComponent={() => null}
                                        >
                                            <MenuItem
                                                value="low"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Low
                                            </MenuItem>

                                            <MenuItem
                                                value="medium"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                Medium
                                            </MenuItem>

                                            <MenuItem
                                                value="high"
                                                sx={{
                                                    padding: '6px 16px',
                                                    color: '#64748B',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                High
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
