import { Box, IconButton, Stack, Typography } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

import StepOne from './StepOne';
import StepTwo from './StepTwo';

//redux
import { connect } from 'react-redux';
import {
    CreateMerchantOnboardingItem,
    EditMerchantOnboardingItem,
    NotifyMerchantEmployees,
} from 'store/actions/onboardingActions';

const PolicyOverwriteModal = (props) => {
    const {
        open,
        handleClose,
        data,
        CreateMerchantOnboardingItem,
        EditMerchantOnboardingItem,
        NotifyMerchantEmployees,
        merchant,
        isNotifyIconClicked,
        type = { type },
    } = props;
    const [img, setImg] = useState('');
    const [new_document, setNew_document] = useState('');
    const [notify, setNotify] = useState(null);
    const [notifyList, setNotifyList] = useState([]);
    const [error, setError] = useState(false);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);

    const theme = useTheme();

    const closeModal = () => {
        handleClose();
        setTimeout(() => {
            setNew_document('');
            setImg('');
            setStep(1);
            setNotify(null);
            setNotifyList([]);
            setError(false);
        }, 100);
    };
    const toNextStep = () => setStep((current) => current + 1);
    const toPreviousStep = () => setStep((current) => current - 1);
    const moveToStep = (activeStep) => setStep(activeStep);
    //step one
    // UPLOAD IMAGE ONCHANGE
    const uploadImage = async (event) => {
        setImageLoading(true);
        const input = event.target;
        const file = input.files?.item(0);
        const base64 = await convertedBase64(file);
        setNew_document(file);
        setError(false);
        setImg(base64);
        setImageLoading(false);
    };
    // CONVERT IMAGE TO BASE 64
    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const dragDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (
            file &&
            (file.type.includes('pdf') ||
                file.type.includes('doc') ||
                file.type.includes('docx') ||
                file.type.includes('video'))
        ) {
            setNew_document(file);
            const reader = new FileReader();
            reader.onload = () => {
                setImg(reader.result);
            };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
    };

    //step two
    const notifyRadioChange = (e) => {
        setNotify(e.target.value);
        setError(false);
    };
    const handleNotifyListSelect = (e) => {
        const {
            target: { value },
        } = e;
        setNotifyList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (notify === null) {
            setError(true);
        } else if (notify === 'true' && !notifyList?.length) {
            toast.info('Please click on change to select employees');
        } else {
            const formData = new FormData();
            formData.append('file', new_document);
            formData.append('tag', data?.tag);
            formData.append('name', data?.name);
            formData.append('notify', notify);
            formData.append('notify_list', JSON.stringify(notifyList));
            !data?.admin && formData.append('merchant', merchant);
            setLoading(true);
            const res = isNotifyIconClicked
                ? await NotifyMerchantEmployees({ policy_id: parseInt(data?.id), notify_list: notifyList })
                : data?.admin
                ? await CreateMerchantOnboardingItem(formData)
                : await EditMerchantOnboardingItem(formData, data?.id);
            setLoading(false);
            if (res?.success) {
                toast.success('Onboarding policy updated successfully');
                closeModal();
            } else {
                toast.error(res?.message);
            }
        }
    };

    useEffect(() => {
        if (open && isNotifyIconClicked) {
            setStep(2);
        }
    }, [isNotifyIconClicked, open]);
    return (
        <AppCenteredModal open={open} handleClose={closeModal} titleStyle={{ mx: 3, px: 0 }} width={540}>
            <Box sx={{ mt: 1, mb: 2 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderBottom: `1px solid ${theme.palette.neutral[90]}` }}
                >
                    <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[30] }}>
                        {step === 1
                            ? 'Policy overwrite'
                            : step === 2
                            ? 'Do you wish to inform your employees of the new changes?'
                            : step === 2 && isNotifyIconClicked
                            ? 'Do you wish to inform your employees of the new changes?'
                            : null}
                    </Typography>
                    <IconButton color="disabled" onClick={closeModal}>
                        <Close />
                    </IconButton>
                </Stack>
                {step === 1 ? (
                    <StepOne
                        dragEnter={() => {}}
                        dragLeave={() => {}}
                        dragDrop={dragDrop}
                        dragOver={dragOver}
                        uploadImage={uploadImage}
                        imageLoading={imageLoading}
                        img={img}
                        new_document={new_document}
                        toNextStep={toNextStep}
                        moveToStep={moveToStep}
                        step={step}
                        data={data}
                        type={type}
                    />
                ) : (
                    <StepTwo
                        handleRadioChange={notifyRadioChange}
                        value={notify}
                        error={error}
                        handleSubmit={handleSubmit}
                        toNextStep={toNextStep}
                        moveToStep={moveToStep}
                        toPreviousStep={toPreviousStep}
                        step={step}
                        handleNotifyListSelect={handleNotifyListSelect}
                        notifyList={notifyList}
                        setNotifyList={setNotifyList}
                        loading={loading}
                        isNotifyIconClicked={isNotifyIconClicked}
                    />
                )}
            </Box>
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant: state?.generalReducers?.user_info?.organization?.id,
    };
};
export default connect(mapStateToProps, {
    CreateMerchantOnboardingItem,
    EditMerchantOnboardingItem,
    NotifyMerchantEmployees,
})(PolicyOverwriteModal);
