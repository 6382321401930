import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
// core components
import BreadCrumb from '../../../../components/Breadcrumb';
import RequirementModal from './PcidssActions/RequirementModal';

// antd components
import { Button, Row, Col, Typography, Card, Modal, Spin, Empty, Tooltip } from 'antd';

// icons
import { DeleteOutlined, EditOutlined, EyeOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllRequirements, CreateRequirement, EditRequirement, DeleteRequirement } from 'store/actions/pcidssActions';

// sub-components
const { Title } = Typography;

const ComplianceDashboard = () => {
    // state
    const [loading, setLoading] = useState({
        content: false,
    });
    const [modal, setModal] = useState(null);
    const [oneReq, setOneReq] = useState({});

    // redux
    const dispatch = useDispatch();
    const all_requirements = useSelector((state) => state?.pcidssReducers?.all_requirements);

    useEffect(() => {
        getAllRequirements();
    }, []);

    // functions
    const openModal = (mode, data) => {
        setModal(mode);
        setOneReq(data);
    };
    const closeModal = () => {
        setModal(null);
    };
    const getAllRequirements = async () => {
        setLoading({ ...loading, content: true });
        const res = await dispatch(GetAllRequirements());
        if (!res.success) {
            toast.error(res?.message);
        }
        setLoading({ ...loading, content: false });
    };
    const deleteRequirement = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this requirement? Note: Deleting this requirement means you want to delete all the subrequirements, documents and audit questions that exists in this requirement',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await dispatch(DeleteRequirement(id, false));
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'PCIDSS' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Requirement
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading.content ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_requirements && all_requirements?.length > 0 ? (
                            <Row wrap gutter={24}>
                                {all_requirements
                                    ?.sort((a, b) => a?.requirement_number - b?.requirement_number)
                                    ?.map((req) => (
                                        <Col
                                            xs={24}
                                            md={12}
                                            lg={8}
                                            xxl={6}
                                            key={req.id}
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <Card
                                                title={`#${req.requirement_number}`}
                                                loading={false}
                                                actions={[
                                                    <Tooltip title="View this requirement" color="blue">
                                                        <Link
                                                            key="preview"
                                                            to={{
                                                                pathname: '/admin/pcidss/requirement',
                                                                state: {
                                                                    req_num: req?.requirement_number,
                                                                    req_id: req?.id,
                                                                    sub_requirements: req?.sub_requirements,
                                                                },
                                                            }}
                                                        >
                                                            <EyeOutlined key="preview" />
                                                        </Link>
                                                    </Tooltip>,
                                                    <Tooltip title="Edit this requirement" color="blue">
                                                        <EditOutlined
                                                            key="edit"
                                                            onClick={() => openModal('Edit', req)}
                                                        />
                                                    </Tooltip>,
                                                    <Tooltip title="Delete this requirement" color="red">
                                                        <DeleteOutlined
                                                            key="delete"
                                                            onClick={() => deleteRequirement(req.id)}
                                                        />
                                                        ,
                                                    </Tooltip>,
                                                ]}
                                            >
                                                <Title level={5} style={{ textAlign: 'center' }}>
                                                    {req.requirement_title}
                                                </Title>
                                            </Card>
                                        </Col>
                                    ))}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Requirements</h3>
                                                <span>Add a new Requirement </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            <RequirementModal
                open={modal}
                handleCancel={closeModal}
                one_req={oneReq}
                CreateRequirement={CreateRequirement}
                EditRequirement={EditRequirement}
            />
        </div>
    );
};

export default ComplianceDashboard;
