import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const BreadCrumb = ({ views }) => {
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/admin">Dashboard</Link>
                </Breadcrumb.Item>
                {views.map((view, index) => (
                    <Breadcrumb.Item key={index}>
                        {!view.link ? (
                            <span>{view?.label}</span>
                        ) : (
                            <Link to={{ pathname: '/admin' + view.link, state: view?.state }}>{view?.label}</Link>
                        )}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </>
    );
};

export default BreadCrumb;
