import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const UploadSignature = (props) => {
    const theme = useTheme();

    // props
    const { image, setImageFile } = props;

    // state
    const [fileBase64URL, setFileBase64URL] = useState(null);

    // UPLOAD IMAGE ONCHANGE
    const uploadImage = (e) => {
        handleFile(e.target.files[0]);
    };
    const handleDrop = (e) => {
        handleFile(e.dataTransfer.files[0]);
    };
    const handleFile = (file) => {
        if (!file) return;
        if (file.type.includes('image')) {
            setImageFile(file);
        } else {
            toast.info('You uploaded an invalid file, please upload an image');
        }
    };

    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const handleCancel = () => {
        setImageFile(null);
    };

    // useEffect
    useEffect(() => {
        if (!image) return setFileBase64URL(null);
        if (typeof image === 'string') return setFileBase64URL(image);
        const reader = new FileReader();
        reader.onload = () => {
            setFileBase64URL(reader.result);
        };
        reader.readAsDataURL(image);
    }, [image]);

    return (
        <Box
            id="drop-zone"
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={handleDrop}
            onDragOver={dragOver}
            sx={{ height: '100%', p: 2 }}
        >
            {!!fileBase64URL && (
                <Box
                    sx={{
                        width: '80px',
                        border: `1px solid ${theme.palette.primary[900]}`,
                        borderRadius: 2,
                        backgroundImage: `url(${fileBase64URL})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '80px',
                        position: 'relative',
                    }}
                >
                    <CloseIcon
                        onClick={handleCancel}
                        sx={{
                            position: 'absolute',
                            top: '-2px',
                            right: '-8px',
                            background: '#858999',
                            borderRadius: 2,
                            width: 14,
                            height: 14,
                            cursor: 'pointer',
                        }}
                    />
                </Box>
            )}
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 15,
                    left: 0,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <label htmlFor="file-input">
                    <Box
                        sx={{
                            border: `2px solid ${theme.palette.primary[900]}`,
                            borderRadius: 2,
                            padding: 1,
                            cursor: 'pointer',
                        }}
                    >
                        <Typography sx={{ color: theme.palette.primary[900], fontWeight: 600, fontSize: 16 }}>
                            Upload a file
                        </Typography>
                    </Box>
                </label>
                <input type="file" accept={'image/*'} onChange={uploadImage} hidden id="file-input" />
                <Typography variant={'body2'} color={'textSecondary'}>
                    or drag and drop a file
                </Typography>
                <input type="file" accept={'image/*'} hidden id="file-input" />
            </Box>
        </Box>
    );
};

export default UploadSignature;
